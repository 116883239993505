import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Col, Grid, Modal } from "rsuite";
import { clientsSendTicket } from "../../../libs/services";
import Toast from "../../../components/Toast";

const ModalTicket = forwardRef(({loader, getData}, ref)=>{
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const [file, setFile] = useState('');
    
    const [dataFile, setDataFile] = useState(null);
    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const [option, setOption] = useState('');

    
    const handleShow = async (id, email, type, option)=>{
        await setOption(option);

        if(option === 'send'){
            await setOpen(true);
        }
        
        await setId(id);
        await setType(type);

        if(option === 'deliver'){
            await onSend(null, {id: id, type: type, option:option});
        }


    }

    const handleClose = ()=>{
        setOpen(false);
        setId('');
        setType('');
        setDataFile(null);
    }

    const handleChange = (e)=>{
        let value = e.target.value;
        let data = e.target.files[0];

        setFile(value);
        setDataFile(data);
    }

    const onSend = async (e, {id, type, option})=>{
        if(e !== null){
            e.preventDefault();
        }
        

        await loader.current.handleShow('Enviando...');

        let error = '';

        if(option === 'send'){
            if(dataFile === null){
                error = 'Debe agregar por lo menos una ficha';
            }
        }
        

        if(error === ''){            
            let obj = new FormData();
            obj.append('id', id);
            obj.append('procedure_type', type);
            obj.append('ticket', dataFile);
            obj.append('option', option);

            let response = await clientsSendTicket(obj);            
            if(response){
                if(response.error){
                    Toast.fire('Error', 'Error al enviar la ficha', 'error');
                }else{
                    handleClose();
                    getData();
                    Toast.fire('Correcto', 'La ficha se envio correctamente', 'success');
                }
                
            }
        }else{
            Toast.fire('Error', error, 'error');
        }
        

        await loader.current.handleClose();
    }


    useImperativeHandle(ref, ()=>({
        handleShow
    }));

    return(
        <Modal size="sm" open={open}>
            <Modal.Header>
                <Modal.Title>Ficha de pago de visa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Col xs={24}>
                        <form onSubmit={(e)=>onSend(e)} encType="multipart/form-data">                            
                            <div className="mt-2">
                                <label>Cargue la ficha</label>
                                <br/>
                                <input type="file" name="file" id="file" value={file} onChange={(e)=>handleChange(e)} />                                
                            </div>
                        </form>
                    </Col>
                    
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                    <Button className="me-2" appearance="ghost" onClick={()=>handleClose()}>Cancelar</Button>
                    
                    <Button type="submit" appearance="primary" onClick={(e)=>onSend(e, {id: id, type: type, option: option})}>
                        Enviar
                    </Button>  
                </div>
            </Modal.Footer>
        </Modal>
    )
});

export default ModalTicket;