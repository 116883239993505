import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Grid, Modal } from 'rsuite';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';

const ProcedureModal = forwardRef((props, ref)=>{
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);   
    const [items, setItems] = useState([]);

    const getProcedures = async ()=>{
        let item = [
            {name:'passport', title:'Pasaporte', active:false},
            {name:'visa', title:'Visa', active:false},
        ];

        await setItems(item);

        await setOpen(true);
        
    }

    const activeOption = ({i})=>{
        let item = items[i];

        if(item.name === 'visa'){
            Swal.fire({
                title:'Atención',
                text:'El cliente cuenta con pasaporte vigente?',
                icon:'info',
                showConfirmButton	: true,
                showCancelButton	: true,
                confirmButtonText	: 'Si, Continuar',
                //cancelButtonColor	: obj.colorCancel,
                cancelButtonText	: 'No, ir a pasaporte'
            }).then((result)=>{
                setOpen(false);

                if(result.isConfirmed){
                    setItems(items);
                    navigate('/procedures/'+item.name);
                }else{
                    navigate('/procedures/passport');
                }
            })
        }else{
            setOpen(false);
            setItems(items);
            navigate('/procedures/'+item.name);
        }
        
    }

    const handleShow = ()=>{
        getProcedures();
    }

    const handleClose = ()=>{
        setOpen(false);
        setItems([]);
    }

    useImperativeHandle(ref, ()=>({
        handleShow
    }));

    return(
        <Modal size="md" open={open} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><span className='text-center'>Seleccione el trámite</span></Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <Grid fluid>
                    <Col xs={24}>
                        {items.map((item, i)=>
                            <Col xs={12} key={i}>
                                <Button
                                    active={item.active}
                                    title={item.title}
                                    action={activeOption}
                                    variable={{i:i}}
                                    main={true}
                                />
                            </Col>
                        )}
                    </Col>
                </Grid>
            </Modal.Body>            
        </Modal>
    ) 
});

export default ProcedureModal;