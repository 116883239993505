import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Grid } from 'rsuite';
import Swal from 'sweetalert2';
import Table from '../../components/Table';
import Title from '../../components/Title';
import { roles, rolesDelete, rolesVerify } from '../../libs/services';

import {pencil} from 'react-icons-kit/fa/pencil';
import {trash} from 'react-icons-kit/fa/trash';
import {lock} from 'react-icons-kit/fa/lock';
import { swalAction } from '../../libs/functions';
import ModalPermissions from './ModalPermissions';
import SystemContext from '../../context/SystemContext';

const Roles = ({loader})=>{
    const list = useRef();
    const permissions = useRef();
    const {getPermission} = useContext(SystemContext);

    const navigate = useNavigate();

    const columns = [
        {title:'Nombre', show:true},
        {title:'Descripción', show:true},
    ];

    const [data, setData] = useState([]);

    const onEdit = (id)=>{
        navigate('/admin/roles/edit/'+id);
    }

    const deleteItem = async(id)=>{
        await loader.current.handleShow('Eliminando...');
        let response = await rolesDelete(id);
        if(response){
            await getData();
        }

        await loader.current.handleClose();
    }

    const onDelete  = (id)=>{
        swalAction({
            title           : 'Alerta',
            text            : 'Desea eliminar el registro?',
            icon            : 'warning',
            textConfirm     : 'Si, eliminar',
            textcancel      : 'No, cancelar',
            values          : id,
            fn              : deleteItem
        });
    }

    const onVerify = async (id)=>{
        let response = await rolesVerify(id);
        if(response){
            if(response === 0){
                onDelete(id);
            }else{
                Swal.fire({icon:'error', title:'Error', text:'No es posible eliminar el rol debido a que uno ó varios usuarios tienen asignado el rol. Cambie el rol de los usuarios e intente nuevamente'});
            }
        }
    }

    const assignPermissions = (id)=>{
        permissions.current.handleShow(id);
    }

    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');
        let response = await roles();
        if(response){
            let rows = [];
            response.forEach((res)=>{
                let obj = {
                    id: res.id,
                    row:[
                        res.display_name,
                        res.description
                    ],
                    buttons:[
                        {icon:lock, title:'Asignar permisos', show:getPermission('admin_permissions_assign'), action:(id)=>assignPermissions(id)},
                        {icon:pencil, title:'Editar', show:getPermission('admin_roles_update'), action:(id)=>onEdit(id)},
                        {icon:trash, title:'Eliminar', show:getPermission('admin_roles_delete'), action:(id)=>onVerify(id)},
                    ]
                };

                let count = 0;
                obj.buttons.forEach((btn)=>{
                    if(!btn.show){
                        count++;
                    }
                });
                
                if(count === obj.buttons.length){
                    obj.buttons = [];
                }

                rows.push(obj);                
            })
            
            await list.current.resetTable();

            await setData(rows);

            setTimeout(async ()=>{
                await list.current.setTable();				
            }, 0)
        }

        await loader.current.handleClose();
    }

    useEffect(()=>{
       getData(); 
       // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Roles" action="Listado" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                {getPermission('admin_roles_create') &&
                    <Col xs={24} style={{position:'relative'}}>
                        <Button 
                            appearance='primary' 
                            size='sm'
                            style={{position:'absolute', right:0, marginTop:25, marginRight:25, zIndex:1}}
                            onClick={()=>navigate('/admin/roles/new')}
                        >
                            Nuevo
                        </Button>
                    </Col>
                }
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <Table 
                            columns={columns}
                            dataList={data}
                            ref={list}
                        />                        
                    </div>                    
                </Col>
            </Grid>

            <ModalPermissions ref={permissions} loader={loader} />            
        </Grid>
    )
}

export default Roles;