import React, {useContext, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Grid, Divider, Button, IconButton, Row, AutoComplete, Message } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import Title from '../../components/Title';
import Toast from '../../components/Toast';
import SystemContext from '../../context/SystemContext';
import { isValidForm, showCtrlError, isAlpha, isNumber } from '../../libs/functions';
import isEmail from 'validator/lib/isEmail';
import TrashIcon from '@rsuite/icons/Trash';

const ClientsForm = ({loader})=>{
    const navigate = useNavigate();
    const {id} = useParams();
    const {getCountryName, getStateName, getCitiesName} = useContext(SystemContext);

    const [client, setClient] = useState({
        names:'',
        lastname1:'',
        lastname2:'',
        birthdate:'',
        sex:'',
        email:'',
        phones:[],
        documents:[],
        scholarships:[],      
        birth_country:'',
        birth_state:'',
        birth_city:''
    });
    const [error_email, setErrorEmail] = useState('');
    
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [states, setStates] = useState([]);
    const [state, setState] = useState('');
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');

    const [scholarship, setScholarship] = useState({
        name:'',
        period:'',
        address:''
    });
    const [error_scholarship, setErrorScholarship] = useState('');

    const [document, setDocument] = useState({
        doc_type:'',
        doc_number:''
    });
    const [error_document, setErrorDoc] = useState('');
    
    const [phone, setPhone] = useState({
        phone_type:'',
        phone_number:''
    });

    let phoneTypes = [{value:'Celular', show:true}, {value:'Casa', show:true}, {value:'Oficina', show:true}];
    let documentTypes = [{value:'Ine', show:true}, {value:'Curp', show:true}];

    const handleChange = (e, variable, valid = '')=>{
        let name = e.target.name;
        let value = e.target.value;
        let form = variable === 'client' ? client : variable === 'scholarship' ? scholarship : variable === 'document' ? document : phone;
        
        let accepted = false;

        form = {
            ...form,
            [name]: value
        };

        if(valid === ''){
            accepted = true;
        }else if(valid === 'alpha'){
            if(isAlpha(value)){
                accepted = true;
            }

            if(value === ''){
                accepted = true;
            }

            if(value.indexOf(' ') !== -1 || value.indexOf('´') !== -1){
                accepted = true;
            }
            
        }else if(valid === 'email'){
            if(!isEmail(value) && value !== ''){
                setErrorEmail('Correo electrónico no valido');
            }else{                
                setErrorEmail('');
            }
            accepted = true
        }else if(valid === 'number'){
            if(isNumber(value)){
               accepted = true; 
            }
        }


        if(variable === 'client' && accepted){
            setClient(form);
        }else if(variable === 'scholarship'){
            setScholarship(form);
        }else if(variable === 'document'){
            setDocument(form);
        }else{
            setPhone(form);
        }

        

        if(value !== ''){
            showCtrlError(name);
        }
    }

    const onSubmit = async ()=>{
        //let response;

        if(isValidForm('form')){
          /*  if(id){                
                response = await usersUpdate(id, user);
            }else{
                response = await usersStore(user);            
            }           
            
            if(response){
                if(response.error){
                    if(response.error.indexOf('Duplicate entry') !== -1){
                        Toast.fire({icon:'error', title:'Error', text:'El nombre que intenta guardar ya se encuentra registrado, intente con un nombre diferente', timer:4500})
                    }
                }else{
                    Toast.fire({icon:'success', title:'Correcto', text:response.mensaje});
                    navigate('/admin/employees');
                }                
            }*/
        }else{
            Toast.fire({icon:'error', title:'Error', text:'Campos incompletos'});
        }
        
    }

    const getData = async (id)=>{
        /*await loader.current.handleShow('Cargando...');
        
        let response = await roles();
        if(response){
            let options = [];
            response.forEach((res)=>{
                if(getPermission('general')){
                    options.push(res);
                }else{
                    if(res.name !== 'administrador_de_sistema'){
                        options.push(res);
                    }
                }
            });
            setRoles(options);
        }

        if(id){
            response = await usersEdit(id);
            if(response){
                setUser(response);
            }            
        }

        await loader.current.handleClose();*/
    }

    const onMount = async ()=>{          
        getData(id);
    }  

    const autocompleteCountry = async (e)=>{
        let value = e;
        setCountry(value);

        if(value !== ''){
            let country = getCountryName(value);
            setCountries(country);
        }
    }

    const autocompleteState = async (e)=>{
        let value = e;
        setState(value);

        if(value !== ''){
            let states = getStateName(client.birth_country, value);
            setStates(states);
        }
    }

    const autocompleteCity = async (e)=>{
        let value = e;
        setCity(value);

        if(value !== ''){
            let cities = getCitiesName(client.birth_state, value);
            setCities(cities);
        }
    }

    const onSelectAutocomplete = (e)=>{
        handleChange(e, 'client');
    }

    const addScholarship = ()=>{
        let form = client;
        let error = '';

        if(scholarship.name === '' || scholarship.address === '' || scholarship.period === ''){
            error = 'Los campos no pueden estar vacios';
        }

        if(form.scholarships.length === 3){
            error = 'Solo es posible agregar 3 registros';
        }

        if(error === ''){
            form.scholarships.push(scholarship);

            setClient(form);
            setScholarship({
                name:'',
                period:'',
                address:''
            });            
        }else{
            setErrorScholarship(error);
        }

        setTimeout(()=>{
            setErrorScholarship('');
        }, 4000);
        
    };

    const removeScholarship = async (i)=>{
        let scholarship = [];
        client.scholarships.forEach((s, index)=>{
            if(index !== i){
                scholarship.push(s);
            }
        });

        let form = client;
    
        form = {
            ...form,
            scholarships: scholarship
        };       

        await setClient(form);
    }

    const addDocument = ()=>{
        let form = client;
        let error = '';

        if(document.doc_type === '' || document.doc_number === ''){
            error = 'Los campos no pueden estar vacios';
        }

        if(error === ''){
            documentTypes.forEach(doc => {
                if(doc.value === document.doc_type){
                    doc.show = false;
                }
            });

            form.documents.push(document);

            setClient(form);
            setDocument({
                doc_type:'',
                doc_number:''
            });            
        }else{
            setErrorDoc(error);
        }

        setTimeout(()=>{
            setErrorDoc('');
        }, 4000);
        
    };

    const removeDocument = async (i)=>{
        let documents = [];
        client.documents.forEach((s, index)=>{
            if(index !== i){
                documents.push(s);
            }
        });

        let form = client;
    
        form = {
            ...form,
            documents: documents
        };       

        await setClient(form);
    }

    useEffect(()=>{        
        onMount();
        
    // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Clientes" action="Registro" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>                
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <div className='p-2 row justify-content-center'>
                            <div className='p-2 col-12 col-sm-10 col-md-12 col-lg-10'>
                                <form>
                                    <fieldset>
                                        <legend style={{fontWeight:'bold'}}>Datos generales</legend>
                                        <Grid fluid>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Nombres</span>
                                                <input className='form-control form-control-sm' name="names" id="names" value={client.names} onChange={(e)=>handleChange(e, 'client', 'alpha')} required disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Apellido paterno</span>
                                                <input className='form-control form-control-sm' name="lastname1" id="lastname1" value={client.lastname1} onChange={(e)=>handleChange(e, 'client', 'alpha')} required disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Apellido materno</span>
                                                <input className='form-control form-control-sm' name="lastname2" id="lastname2" value={client.lastname2 || ""} onChange={(e)=>handleChange(e, 'client', 'alpha')} disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Fecha de nacimiento</span>
                                                <input type="date" className='form-control form-control-sm' name="birthdate" id="birthdate" value={client.birthdate || ""} onChange={(e)=>handleChange(e, 'client')} disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Género</span>
                                                <select className='form-control form-control-sm' name="sex" id="sex" value={client.sex || ""} onChange={(e)=>handleChange(e, 'client')} disabled={id !== undefined}>
                                                    <option value="">Seleccione...</option>
                                                    <option value="Femenino">Femenino</option>
                                                    <option value="Masculino">Masculino</option>                                                    
                                                </select>
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Email</span> {error_email !== '' && <span style={{color:'red', fontSize:10}}>{error_email}</span>}
                                                <input type="email" className='form-control form-control-sm' name="email" id="email" value={client.email} onChange={(e)=>handleChange(e, 'client', 'email')} required disabled={id !== undefined} />
                                            </Col>
                                        </Grid>
                                    </fieldset>
                                    <fieldset>
                                        <legend style={{fontWeight:'bold'}}>Lugar de nacimiento</legend>
                                        <Row>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>País</span>
                                                <AutoComplete data={countries} value={country} 
                                                    onChange={(e)=>autocompleteCountry(e)} 
                                                    onSelect={(e)=>onSelectAutocomplete({target:{name:'birth_country', value:e}})}
                                                />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Estado</span>
                                                <AutoComplete data={states} value={state} 
                                                    onChange={(e)=>autocompleteState(e)} 
                                                    onSelect={(e)=>onSelectAutocomplete({target:{name:'birth_state', value:e}})}
                                                />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Ciudad</span>
                                                <AutoComplete data={cities} value={city} 
                                                    onChange={(e)=>autocompleteCity(e)} 
                                                    onSelect={(e)=>onSelectAutocomplete({target:{name:'birth_state', value:e}})}
                                                />
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset>
                                        <legend style={{fontWeight:'bold'}}>Estudios</legend>
                                        <Row>
                                            {error_scholarship !== "" &&
                                                <Col xs={24}>
                                                    <Message type="error">{error_scholarship}</Message>
                                                </Col>
                                            }
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Nombre de escuela</span>
                                                <input className='form-control form-control-sm' name="name" id="name" value={scholarship.name} onChange={(e)=>handleChange(e, 'scholarship')} disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={12} lg={8} className="pb-2">
                                                <span>Dirección</span>
                                                <input className='form-control form-control-sm' name="address" id="address" value={scholarship.address} onChange={(e)=>handleChange(e, 'scholarship')} disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={10} lg={6} className='pb-2'>
                                                <span>Período</span>
                                                <input className='form-control form-control-sm' name="period" id="period" value={scholarship.period} onChange={(e)=>handleChange(e, 'scholarship')} disabled={id !== undefined} />
                                            </Col>
                                            <Col xs={24} md={2} lg={2} className='pb-2 text-end'>
                                                <IconButton appearance='primary' size="sm" icon={<PlusIcon />} style={{marginTop:25, width:'100%'}} onClick={()=>addScholarship()}/>
                                            </Col>
                                            <div className='pb-2 col-12'>
                                                <table className='table table-responsive table-hover'>
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre de escuela</th>
                                                            <th>Dirección</th>
                                                            <th>Período</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {client.scholarships.map((school, i)=>
                                                            <tr key={i}>
                                                                <td>{school.name}</td>
                                                                <td>{school.address}</td>
                                                                <td>{school.period}</td>
                                                                <td>
                                                                    <IconButton icon={<TrashIcon />} size="sm" appearance='ghost' onClick={()=>removeScholarship(i)}/>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>                                                    
                                                </table>
                                            </div>
                                        </Row>
                                    </fieldset>
                                    <fieldset>
                                        <legend style={{fontWeight:'bold'}}>Documentos</legend>                                        
                                            <Row>
                                                {error_document !== "" &&
                                                    <Col xs={24}>
                                                        <Message type="error">{error_document}</Message>
                                                    </Col>
                                                }
                                                <Col xs={24} md={12} lg={8}>
                                                    <span>Tipo</span>
                                                    <select className='form-control form-control-sm' id="doc_type" name="doc_type" value={document.doc_type} onChange={(e)=>handleChange(e, 'document')} disabled={id !== undefined}>
                                                        <option value="">Seleccione...</option>
                                                        {documentTypes.map((doc, i)=>
                                                            doc.show &&
                                                                <option key={i} value={doc.value}>{doc.value}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                                <Col xs={24} md={10} lg={6}>
                                                    <span>Número</span>
                                                    <input className='form-control form-control-sm' id="doc_number" name="doc_number" value={document.doc_number} onChange={(e)=>handleChange(e, 'document', 'number')} disabled={id !== undefined}/>
                                                </Col>
                                                <Col xs={24} md={2} lg={2} className='pb-2 text-end'>
                                                    <IconButton appearance='primary' size="sm" icon={<PlusIcon />} style={{marginTop:25, width:'100%'}} onClick={()=>addDocument()}/>
                                                </Col>
                                                <Col xs={24} className='pt-2'>
                                                    <table className='table table-responsive table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo</th>
                                                                <th>Número</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                            <tbody>
                                                            {client.documents.map((doc, i)=>
                                                                <tr key={i}>
                                                                    <td>{doc.doc_type}</td>
                                                                    <td>{doc.doc_number}</td>
                                                                    <td>
                                                                        <IconButton icon={<TrashIcon />} size="sm" appearance='ghost' onClick={()=>removeDocument(i)}/>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>    
                                                    </table>
                                                </Col>
                                            </Row>
                                    </fieldset>
                                    <fieldset>
                                        <legend style={{fontWeight:'bold'}}>Teléfonos</legend>
                                        <Row>
                                            <Col xs={24}>
                                                <Row>
                                                    <Col xs={24} md={12} lg={8}>
                                                        <span>Tipo</span>
                                                        <select className='form-control form-control-sm' id="phone_type" name="phone_type" value={document.phone_type} onChange={(e)=>handleChange(e, 'phone')} disabled={id !== undefined}>
                                                            <option value="">Seleccione...</option>
                                                            {phoneTypes.map((phone, i)=>
                                                                    phone.show &&
                                                                    <option key={i} value={phone.value}>{phone.value}</option>
                                                            )}
                                                        </select>
                                                    </Col>
                                                    <Col xs={24} md={10} lg={6}>
                                                        <span>Número</span>
                                                        <input className='form-control form-control-sm' id="phone_number" name="phone_number" value={document.phone_number} onChange={(e)=>handleChange(e, 'phone')} disabled={id !== undefined}/>
                                                    </Col>
                                                    <Col xs={24} md={2} lg={2} className='pb-2 text-end'>
                                                        <IconButton appearance='primary' size="sm" icon={<PlusIcon />} style={{marginTop:25, width:'100%'}} />
                                                    </Col>
                                                    <Col xs={24} className='pt-2'>
                                                        <table className='table table-responsive table-hover'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tipo</th>
                                                                    <th>Número</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {client.phones.map((phone, i)=>
                                                                    <tr key={i}>
                                                                        <td>{phone.type}</td>
                                                                        <td>{phone.number}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Col>                                                                                                            
                                        </Row>
                                    </fieldset>
                                        
                                    <div className='pt-2 col-12 text-center'>
                                        <Button appearance='ghost' className='me-3' onClick={()=>navigate('/admin/employees')}>Cancelar</Button>
                                        <Button appearance='primary' onClick={()=>onSubmit()}>Guardar</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>                    
                </Col>
            </Grid>            
        </Grid>
    )
}

export default ClientsForm;