import moment from "moment";
import React, {forwardRef, useState, useImperativeHandle} from "react";
import { Button, Col, Grid, MaskedInput, Modal } from "rsuite";
import Toast from "../../../components/Toast";
import { salesDetailsSave} from "../../../libs/services";

const ModalSchedule = forwardRef(({getData, loader}, ref)=>{
    const [open, setOpen] = useState(false);
    const [cas, setCas] = useState({
        date:'',
        office:'CAS',
        time:''
    });
    const [consul, setConsul] = useState({
        date:'',
        office:'Consulado',
        time:''
    });
    const [salesId, setSalesId] = useState('');

    const handleShow = (sales_id, cas, consul)=>{
        setOpen(true);
        setSalesId(sales_id);

        if(cas !== null){
            cas.date = moment(cas.appointment_date).format('DD/MM/YYYY');
            cas.time = moment(cas.schedule, 'HH:mm').format('HH:mm');

            setCas(cas)
        }

        if(consul !== null){
            consul.date = moment(consul.appointment_date).format('DD/MM/YYYY');
            consul.time = moment(consul.schedule, 'HH:mm').format('HH:mm');
            setCas(consul)
        }

    }

    const handleClose = ()=>{
        setOpen(false);
        setSalesId('');
        setCas({
            date:'',
            office:'CAS',
            time:''
        });
        setConsul({
            date:'',
            office:'Consulado',
            time:''
        });
    }

    const handleChange = (name, value, variable)=>{
        if(variable === 'cas'){
            setCas({
                ...cas,
                [name]: value
            });
        }else if(variable === 'consul'){
            setConsul({
                ...consul,
                [name]: value
            });
        }

    }

    const onAssignSchedule = async ()=>{
        await loader.current.handleShow();

        let obj = [];

        if(cas.date !== '' && cas.time !== ''){
            obj.push({
                sales_id: salesId, 
                id: cas.id,
                appointment_date: moment(cas.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                office: cas.office,
                schedule: cas.time
            });
        }

        if(consul.date !== '' && cas.time !== ''){
            obj.push({
                sales_id: salesId, 
                id: consul.id,
                appointment_date: moment(consul.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                office: consul.office,
                schedule: consul.time
            })
        }

        let error = ''
        if(obj.length === 0){
            error = 'Debe agregar una fecha y una hora';
        }

        if(error === '' ){
            if(moment(cas.date, 'DD/MM/YYYY').format('YYYY-MM-DD') === 'Invalid date' || moment(consul.date, 'DD/MM/YYYY').format('YYYY-MM-DD') === 'Invalid date'){
                error += 'Fecha '
            }
            
            if(moment(cas.time, 'HH:mm').format('HH:mm') === 'Invalid date' || moment(consul.time, 'HH:mm').format('HH:mm') === 'Invalid date'){
                error += (error !== '' ? 'y' : '')+ ' Hora '
            }

            if(error !== ''){
                error += error+'no valida';
            }
            
        }
            
        if(error === ''){
            let response = await salesDetailsSave(obj);

            if(response){
                Toast.fire('Correcto', response.message, 'success');
                getData(salesId);
                handleClose();
            }
        }else{
            Toast.fire('Error', error, 'error');
        }
        

        await loader.current.handleClose();
        
    }

    useImperativeHandle(ref, ()=>({
        handleShow
    }));

    return(
        <Modal open={open} size="xs">
            <Modal.Header>
                <Modal.Title>Asignar fecha de cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Col xs={24} className="schedule-form">
                        <fieldset>
                            <legend>CAS</legend>
                            <div className="mb-2">                            
                                <Col xs={12}>
                                    <label>Fecha</label>
                                    {/*<input type="date" className="form-control form-control-sm" />*/}
                                    
                                    <MaskedInput 
                                        value={cas.date}
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        guide
                                        keepCharPositions
                                        placeholder={'DD/MM/YYYY'}
                                        placeholderChar={'_'}
                                        className="form-control form-control-sm"
                                        onChange={(val)=>handleChange('date', val, 'cas')}
                                        required
                                    />
                                    
                                </Col>
                                <Col xs={12}>
                                    <label>Hora</label>
                                    {/*<input type="time" className="form-control form-control-sm" />*/}
                                    <MaskedInput 
                                        value={cas.time}
                                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                        guide
                                        keepCharPositions
                                        placeholder={'HH:mm'}
                                        placeholderChar={'_'}
                                        className="form-control form-control-sm"
                                        onChange={(val)=>handleChange('time', val, 'cas')}
                                        required
                                />
                                </Col>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Consulado</legend>
                            <div className="mb-2">
                                <Col xs={12}>
                                    <label>Fecha</label>
                                    <MaskedInput 
                                        value={consul.date}
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        guide
                                        keepCharPositions
                                        placeholder={'DD/MM/YYYY'}
                                        placeholderChar={'_'}
                                        className="form-control form-control-sm"
                                        onChange={(val)=>handleChange('date', val, 'consul')}
                                        
                                    />
                                </Col>
                                <Col xs={12}>
                                    <label>Hora</label>
                                    <MaskedInput 
                                        value={consul.time}
                                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                        guide
                                        keepCharPositions
                                        placeholder={'HH:mm'}
                                        placeholderChar={'_'}
                                        className="form-control form-control-sm"
                                        onChange={(val)=>handleChange('time', val, 'consul')}
                                        
                                    />
                                </Col>
                            </div>
                        </fieldset>
                        
                    </Col>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="ghost" className="btn-cancel" onClick={()=>handleClose()}>Cancelar</Button>
                <Button appearance="primary" className="btn-payment" onClick={()=>onAssignSchedule()}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
});

export default ModalSchedule;