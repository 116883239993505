import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Divider, MaskedInput, Row } from "rsuite";
import isEmail from "validator/lib/isEmail";
import { isValidForm, transformAmount } from "../../../libs/functions";
import { Table } from "react-bootstrap";

const Payments = forwardRef(({
    sale,
    setSale,
    clients,
    handleChange,
    sites,
    sellers,
    flyers
}, ref)=>{
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [errorOther, setErrorOther] = useState('');
    const [details, setDetails] = useState([]);
    const [total, setTotal] = useState('');
    const [minPayment, setMinPayment] = useState(true);
    const [otherPayment, setOtherPayment] = useState(false);
    const [otherAmount, setOtherAmount] = useState('');
    const [totalMin, setTotalMin] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [showPayments, setShowPayments] = useState(false);

    const onChangeForm = (e, key = '')=>{
        if(e.target.name === 'email'){
            setErrorEmail('');

            if(e.target.value !== ''){
                if(!isEmail(e.target.value)){
                    setErrorEmail('Correo electrónico no valido');
                }
            }            
        }

        if(e.target.name === 'phone'){
            setErrorPhone('');

            if(e.target.value !== ''){
                if(e.target.value.indexOf('_') !== -1){
                    setErrorPhone('Correo electrónico no valido');
                }
            }            
        }

        handleChange(e, key);

    }

    const validateForm = ()=>{
        let error = '';

        if(!isValidForm('div.payment-form')){
            error = 'Campos incompletos';
        }else if(
            errorEmail !== '' || 
            errorOther !== '' ||
            errorPhone !== ''
        ){
            error = 'Existen errores en los datos capturados, verifique la información';
        }

        details.forEach((detail)=>{
            if(detail.pack === '' && error === '' && detail.procedure === 'Visa'){
                error = 'Debe seleccionar un concepto';
            }
        })

        return error;
    }

    useImperativeHandle(ref, ()=>({
        validateForm,
    }));

    const parseDataClients = ()=>{
        let dataDetails = [];

        clients.forEach((client)=>{
            let procedure = '';
            let data = {};
            if(client.selection.procedure.passport){
                procedure = 'Pasaporte';
            }

            if(client.selection.procedure.visa){
                procedure = 'Visa';
            }

            let index =  dataDetails.findIndex(obj => obj.procedure === procedure);
            
            if(index === -1){
                data = {
                    procedure: procedure,
                    pack:'',
                    amount:1,
                    price:'',
                    subtotal:''
                }
            }else{
                data = dataDetails[index];
                data.amount = data.amount+1;
            }
            

            if(client.selection.procedure.passport){
                data = {
                    ...data,
                    price:350                    
                };

                data.subtotal = data.amount * data.price;
                
            }

            if(index === -1){
                dataDetails.push(data);
            }else{
                dataDetails[index] = data;
            }
            
        })

        setDetails(dataDetails);
        
    }

    const onChangePack = (e, i)=>{
        let value = e.target.value;
        
        let data = details.map((detail, index)=>{
            if(i === index){
                if(value !== ''){                   
                    setShowPayments(true);
                    if(value === '1'){
                        detail.price = 3000;                        
                    }

                    if(value === '2'){
                        detail.price = 1950;
                    }
                    
                }else{
                    setMinPayment(true);
                    setOtherPayment(false);
                    setShowPayments(false);
                    detail.price = 0;
                }

                detail.subtotal = detail.amount * detail.price;

                detail.pack = value;
            }

            return detail;
        });

        setDetails(data);

    }

    const onChangePayment = (e)=>{
        let name = e.target.name;

        if(name === 'minPayment'){
            setMinPayment(true);
            setOtherPayment(false);
        }

        if(name === 'otherPayment'){
            setMinPayment(false);
            setOtherPayment(true);
            setOtherPayment(totalMin);
        }
    }

    const onCalculateTotal = (e)=>{
        let value = e.target.value;

        setOtherAmount(value);
        setTotalToPay(value);

        if(value > total){
            setErrorOther('La cantidad no puede ser mayor al total de trámite');
        }else if(value < 0){
            setErrorOther('La cantidad no puede ser menor a el pago por inicio de tramite');
        }else{
            setErrorOther('');
        }
    }

    useEffect(()=>{
        let subtotal = 0;
        let minPayment = 0;
        let pack = '';

        details.forEach((detail)=>{
            subtotal += detail.subtotal;

            if(detail.procedure === 'Visa'){
                minPayment += 500;
            }else{
                minPayment += 350;
            }

            if(pack.indexOf(detail.procedure) === -1){
                pack += detail.procedure+', ';
            }
        });

        setSale({
            ...sale,
            total: subtotal,
            advancePayment: minPayment,
            payable: subtotal - minPayment,
            salePack: pack
        });
        setTotal(subtotal);
        setTotalMin(minPayment);
        setOtherAmount(minPayment);
        setTotalToPay(minPayment);
        // eslint-disable-next-line
    },[details]);

    useEffect(()=>{
        parseDataClients();
        // eslint-disable-next-line
    },[]);

    return (
        <Col xs={24} className="mb-3 payment-form">
            <fieldset>
                <legend>Datos de venta</legend>
                <Col xs={24}>
                    <Col xs={24} md={4}>
                        <label>Sucursal</label>
                        <select className="form-control form-control-sm"
                            name="sites_id"
                            id="sites_id"
                            value={sale.sites_id}
                            onChange={(e)=>handleChange(e)}
                            required
                        >
                            <option value="">Seleccione...</option>
                            {sites.map((site, i)=>
                                <option key={i} value={site.id}>{site.name}</option>
                            )}
                        </select>
                    </Col>
                    <Col xs={24} md={4}>
                        <label>Vendedor(a)</label>
                        <select className="form-control form-control-sm"
                            name="seller_id"
                            id="seller_id"
                            value={sale.seller_id}
                            onChange={(e)=>handleChange(e)}
                            required
                        >
                            <option value="">Seleccione...</option>
                            {sellers.map((seller, i)=>
                                <option key={i} value={seller.id}>
                                    {seller.names+' '+seller.lastname1+(seller.lastname2 !== null ? ' '+seller.lastname2 : '')}
                                </option>
                            )}
                        </select>
                    </Col>
                    <Col xs={24} md={4}>
                        <label>Medio de contacto</label>
                        <select className="form-control form-control-sm"
                            name="contact_type"
                            id="contact_type"
                            value={sale.contact_type || ""}
                            onChange={(e)=>handleChange(e)}
                        >
                            <option value="">Seleccione...</option>
                            <option value="Correo electrónico">Correo electrónico</option>
                            <option value="Redes sociales">Redes sociales</option>
                            <option value="Teléfono">Teléfono</option>
                            <option value="Volante">Volante</option>
                            <option value="Visita presencial">Visita presencial</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </Col>
                    {sale.contact_type === 'Volante' &&
                        <Col xs={24} md={4}>
                            <label>Volanteo</label>
                            <select className="form-control form-control-sm"
                                name="flyer_id"
                                id="flyer_id"
                                value={sale.flyer_id || ""}
                                onChange={(e)=>handleChange(e)}
                            >
                                <option value="">Seleccione...</option>
                                {flyers.map((flyer, i)=>
                                    <option key={i} value={flyer.id}>
                                        {flyer.names+' '+flyer.lastname1+(flyer.lastname2 !== null ? ' '+flyer.lastname2 : '')}
                                    </option>
                                )}
                            </select>
                        </Col>
                    }
                </Col>
            
                <Col xs={24}>
                    <Divider />
                    <Row>
                        <Col xs={24} lg={6}>
                            <label>Nombre completo</label>
                            <input className="form-control form-control-sm" name="name" id="name" value={sale.client.name} 
                                onChange={(e)=>handleChange(e, 'client')} required
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <label>Correo electrónico</label>
                            {errorEmail !== '' && <span className="error-text">{errorEmail}</span>}
                            <input className="form-control form-control-sm" name="email" id="email" value={sale.client.email} 
                                onChange={(e)=>onChangeForm(e, 'client')} required/>
                        </Col>
                        <Col xs={24} lg={4}>
                            <label>Teléfono</label>
                            {errorPhone !== '' && <span className="error-text">{errorPhone}</span>}
                            <MaskedInput
                                value={sale.client.phone || ""}
                                mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                guide
                                keepCharPositions
                                placeholder={'000-000-0000'}
                                placeholderChar={'_'}
                                className="form-control form-control-sm"
                                onChange={(e)=>onChangeForm({
                                    target:{
                                        name:'phone',
                                        value: e
                                    }
                                }, 'client')}
                                id="phone"
                                name="client_phone"
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={3}>
                            <label>Método de pago</label>
                            <select className="form-control form-control-sm" name="methodPayment" id="methodPayment"
                                value={sale.methodPayment} onChange={(e)=>handleChange(e)} required
                            >
                                <option value="">Seleccione...</option>
                                <option value="efectivo">Efectivo</option>
                                <option value="tarjeta">Tarjeta</option>
                            </select>
                        </Col>
                        {sale.methodPayment === 'tarjeta' && (
                            <Col xs={24} lg={3}>
                                <label>No. referencia</label>
                                <input className="form-control form-control-sm" name="reference" id="reference" 
                                    value={sale.reference} onChange={(e)=>handleChange(e)} required
                                />
                            </Col>
                        )}
                        <Col xs={24} lg={3}>
                            <label>No. Recibo</label>
                            <input className="form-control form-control-sm" name="receipt" id="receipt" type="number"
                                value={sale.receipt} onChange={(e)=>handleChange(e)} required
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Col xs={24}>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Trámite</th>
                                    <th>Concepto</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((detail, i)=>
                                    <tr key={i}>
                                        <td>{detail.procedure}</td>
                                        <td>
                                            {detail.procedure === 'Visa' && (
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="pack"
                                                    id="pack"
                                                    value={detail.pack}
                                                    onChange={(e)=>onChangePack(e, i)}
                                                >
                                                    <option value="">Seleccione...</option>
                                                    <option value="1">Trámite y Transporte</option>
                                                    <option value="2">Trámite</option>
                                                </select>	
                                            )}
                                        </td>
                                        <td>{detail.amount}</td>
                                        <td>{detail.price !== '' && '$ '+transformAmount((detail.price).toFixed(2))+' MXN'}</td>
                                        <td>{detail.subtotal !== '' && '$ '+transformAmount((detail.subtotal).toFixed(2))+' MXN'}</td>
                                    </tr>
                                )} 
                                {showPayments && (
                                    <>
                                        <tr>
                                            <th colSpan={4}>
                                                <Col xs={4}>
                                                    <input type="radio" name="minPayment" value={minPayment} checked={minPayment} onChange={(e)=>onChangePayment(e)} />
                                                </Col>
                                                <Col xs={20}>
                                                    <span className="block">Pago por inicio de tramite</span> 
                                                    <span className="block" style={{fontSize:12}}>(se descuenta del total a pagar)</span>
                                                </Col>														
                                            </th>
                                            <th>
                                                $ {transformAmount(totalMin)+'.00 MXN'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>
                                                <Col xs={4}>
                                                    <input type="radio" name="otherPayment" value={otherPayment} checked={otherPayment} onChange={(e)=>onChangePayment(e)} />
                                                </Col>
                                                <Col xs={20}>
                                                    <span className="block">Otra cantidad</span> 
                                                    <span className="block" style={{fontSize:12}}>(Debe cubrir el pago inicial o el monto total)</span>
                                                </Col>														
                                            </th>
                                            <th>
                                                {errorOther !== '' && (
                                                    <span className="error-text">{errorOther}</span>
                                                )}
                                                <input type="number" name="otherAmount" className="form-control form-control-sm" value={otherAmount} disabled={!otherPayment} 
                                                    required={otherPayment}
                                                    onChange={(e)=>onCalculateTotal(e)} />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-end" colSpan={4}>Total de tramite</th>
                                            <td>{total !== '' && '$ '+transformAmount(parseFloat(total).toFixed(2))+' MXN'}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-end" colSpan={4}>Total a pagar</th>
                                            <td style={{color:'red'}}>{(totalToPay) !== '' && '$ '+transformAmount(parseFloat(totalToPay).toFixed(2))+' MXN'}</td>
                                            
                                        </tr>
                                        <tr>
                                            <th className="text-end" colSpan={4}>Total restante</th>
                                            <td >{total !== '' && '$ '+transformAmount(parseFloat(total - totalToPay).toFixed(2))+' MXN'}</td>
                                        </tr>
                                    </>
                                )}                         
                                
                            </tbody>
                        </Table>
                    </Col>
                </Col>
            </fieldset>
        </Col>
    )
})

export default Payments; 