import { Col, Divider, Grid, Row } from "rsuite";
import Button from "../../../components/Button";

const ButtonSelections = ({
    selectionProcedure,
    onActiveOption,
})=>{
    return(
        <Grid fluid>
            <Col xs={24}>
                <Row>
                    <Col xs={24}>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Pasaporte"
                                active={selectionProcedure.procedure.passport}
                                variable={{key:'procedure', name:'passport', value:!selectionProcedure.procedure.passport}}
                                action={onActiveOption}
                            />
                        </Col>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Visa"
                                active={selectionProcedure.procedure.visa}
                                variable={{key:'procedure', name:'visa', value:!selectionProcedure.procedure.visa}}
                                action={onActiveOption}
                            />
                        </Col>                                            
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                </Row>
                
                <Row>
                    <Col xs={24}>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Mayor de edad"
                                active={selectionProcedure.person.old}
                                variable={{key:'person', name:'old', value:!selectionProcedure.person.old}}
                                action={onActiveOption}
                            />
                        </Col>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Menor de edad"
                                active={selectionProcedure.person.new}
                                variable={{key:'person', name:'new', value:!selectionProcedure.person.new}}
                                action={onActiveOption}
                            />
                        </Col>                                            
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                </Row>
                {selectionProcedure.procedure.visa && (
                    <Row>
                        <Col xs={24}>
                            <Col xs={24} lg={selectionProcedure.person.new ? 24 : 12} className="mb-2">
                                <Button 
                                    title="Turista"
                                    active={selectionProcedure.visaType.tourist}
                                    variable={{key:'visaType', name:'tourist', value:!selectionProcedure.visaType.tourist}}
                                    action={onActiveOption}
                                />
                            </Col>
                            {!selectionProcedure.person.new && (
                                <Col xs={24} lg={12} className="mb-2">
                                    <Button 
                                        title="Trabajo"
                                        active={selectionProcedure.visaType.work}
                                        variable={{key:'visaType', name:'work', value:!selectionProcedure.visaType.work}}
                                        action={onActiveOption}
                                    />
                                </Col>
                            )}
                            
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </Row>
                )}
                
                
                   
                <Row>
                    <Col xs={24}>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Primera vez"
                                active={selectionProcedure.type.first}
                                variable={{key:'type', name:'first', value:!selectionProcedure.type.first}}
                                action={onActiveOption}
                            />
                        </Col>
                        <Col xs={24} lg={12} className="mb-2">
                            <Button 
                                title="Renovación"
                                active={selectionProcedure.type.renew}
                                variable={{key:'type', name:'renew', value:!selectionProcedure.type.renew}}
                                action={onActiveOption}
                            />
                        </Col>                                            
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                </Row>
                    
                    
                {(selectionProcedure.type.renew && (selectionProcedure.procedure.passport || selectionProcedure.procedure.visa)) && (
                    <Row>
                        <Col xs={24}>
                            <Col xs={24} lg={selectionProcedure.procedure.passport ? 12 : 24} className="mt-2">
                                <Button 
                                    title="Vencida o por vencer"
                                    active={selectionProcedure.options.expired}
                                    variable={{key:'options', name:'expired', value:!selectionProcedure.options.expired}}
                                    action={onActiveOption}
                                />
                            </Col>
                            <Col xs={24} lg={selectionProcedure.procedure.passport ? 12 : 24} className="mt-2">
                                <Button 
                                    title="Extraviado"
                                    active={selectionProcedure.options.lost}
                                    variable={{key:'options', name:'lost', value:!selectionProcedure.options.lost}}
                                    action={onActiveOption}
                                />
                            </Col>
                            {selectionProcedure.procedure.visa && (
                                <Col xs={24} className="mt-2">
                                    <Button 
                                        title="Vencido +4 años"
                                        active={selectionProcedure.options.expired_years}
                                        variable={{key:'options', name:'expired_years', value:!selectionProcedure.options.expired_years}}
                                        action={onActiveOption}
                                    />
                                </Col>
                            )}

                            {selectionProcedure.procedure.passport && (
                                <>
                                    <Col xs={24} lg={12} className="mt-2">
                                        <Button 
                                            title="Deteriorado"
                                            active={selectionProcedure.options.damaged}
                                            variable={{key:'options', name:'damaged', value:!selectionProcedure.options.damaged}}
                                            action={onActiveOption}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12} className="mt-2">
                                        <Button 
                                            title="Cambio de datos"
                                            active={selectionProcedure.options.change}
                                            variable={{key:'options', name:'change', value:!selectionProcedure.options.change}}
                                            action={onActiveOption}
                                        />
                                    </Col>
                                </>
                                
                            )}
                            
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </Row>
                )}
                    
            </Col>
            
        </Grid>
    )
}

export default ButtonSelections;