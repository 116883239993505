import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { isAuth } from './libs/functions';

import ClientsForm from './pages/clients/Form';
import Clients from './pages/clients/List';

import Home from './pages/Home';
import Login from './pages/Login';

import Permissions from './pages/Permissions';

import Procedures from './pages/procedures/List';
import Details from './pages/procedures/Details';
import NewProcedure from './pages/procedures/register/New';

import RolesForm from './pages/roles/Form';
import Roles from './pages/roles/List';

import SitesForm from './pages/sites/Form';
import SitesList from './pages/sites/List';

import UsersForm from './pages/users/Form';
import Users from './pages/users/List';



const ProtectedRoute = ()=>{
    var auth = isAuth();

    if(!auth){
        return <Navigate to="login" replace />;
    }

    return <Outlet />;

}


const AppRoutes = (props)=>{
    return(
        <Routes>
            <Route path={`/login`} exact element={<Login {...props} />} />

            <Route element={<ProtectedRoute />}>
                <Route element={<Layout {...props} />}>
                
                    <Route path={`/`} exact element={<Home reset={props.reset} />} />

                    <Route path='/admin/permissions' exact element={<Permissions {...props} />} />
                    
                    <Route path='/admin/roles' exact element={<Roles {...props} />} />
                    <Route path='/admin/roles/new' exact element={<RolesForm {...props} />} />
                    <Route path='/admin/roles/edit/:id' exact element={<RolesForm {...props} />} />

                    <Route path='/admin/employees' exact element={<Users {...props} />} />
                    <Route path='/admin/employees/new' exact element={<UsersForm  {...props} />} />
                    <Route path='/admin/employees/edit/:id' exact element={<UsersForm  {...props} />} />
                    
                    <Route path="/admin/sites" exact element={<SitesList {...props} />} />
                    <Route path="/admin/sites/new" exact element={<SitesForm {...props} />} />
                    <Route path="/admin/sites/edit/:id" exact element={<SitesForm {...props} />} />
                    

                    <Route path='/clients' exact element={<Clients loader={props.loader} />} />
                    <Route path='/clients/new' exact element={<ClientsForm loader={props.loader} />} />

                    <Route path='/procedures' exact element={<Procedures loader={props.loader} />} />
                    <Route path='/procedures/new' exact element={<NewProcedure loader={props.loader} />} />
                    <Route path={`/procedures/visa/details/:id`} exact element={<Details loader={props.loader} reset={props.reset} />} />

                    <Route path={`/visa/details`} exact element={<Details loader={props.loader} />} />
                    
                    
                    
                </Route>                
            </Route>
        </Routes>
    )
}

export default AppRoutes;