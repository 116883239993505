
import { decript } from "./functions"
import countries from './jsonCountries/countries.json';
import states from './jsonCountries/states.json';

const api = process.env.REACT_APP_HOST;



export const fetchRequest = ({
    url,
    method = 'GET',
    obj = null,
    requireToken = true,
    sendFile = false
})=>{
    let token = decript('token');
    
    return fetch(api+url,{
        method:method,
        body: obj !== null ? sendFile ? obj : JSON.stringify(obj) : null,
        headers: new Headers(
            requireToken ?
                sendFile ? 
                    {
                        'Authorization' : 'Bearer '+token,    
                        //"content-type"  : "multipart/form-data",
                        'Access-Control-Allow-Origin': '*',
                    }
                :
                    {
                        'Authorization' : 'Bearer '+token,
                        'Content-Type'  : 'application/json',
                        'Accept'        : 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    }
            : 
                {
                    'Content-Type'  : 'application/json',
                    'Accept'        : 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
        )
    }).then(res => {
        if(res.ok){
            return res.json();
        }else{
            res.text().then(msg => console.log(msg));
        }
    }).then(response => {
        if(response){
            return response;
        }
    })
}

/* services */

const clients = ()=>{
    return fetchRequest({
        url:'clients',
    });
};

const clientsId = (id)=>{
    return fetchRequest({url:'clients/'+id});
};

const clientsSearch = (query)=>{
    return fetchRequest({
        url:'clients/search/'+query
    });
};

const clientsSendTicket = (obj)=>{
    return fetchRequest({url:'clients/sendTicket', method:'POST', obj:obj, sendFile: true});
};

const clientsUsersStore = (obj)=>{
    return fetchRequest({url:'clients/users', method:'POST', obj:obj});
}

export const getCountries = async ()=>{
    return countries;
}

export const getStates = async (value)=>{
    let country = countries.countries.find(obj => obj.name.toUpperCase() === value.toUpperCase());
    
    return states.states.filter((state)=> state.id_country === country.id);    
}

export const getCities = async (value)=>{
    let token = sessionStorage.getItem('token');

   return fetch('https://www.universal-tutorial.com/api/cities/'+value,{
       method:'GET',
       headers: new Headers({
           "Authorization": "Bearer "+token,
           "Accept": "application/json"
       })
   }).then( res => res.json())
   .then(response => response )
   
}

const login = (obj)=>{
    return fetchRequest({
        url:'auth/login',
        method:'POST',
        obj:obj
    });
};

const logout = ()=>{
    return fetchRequest({
        url:'auth/logout'
    });
};

const permissionasAssigned = (id)=>{
    return fetchRequest({
        url:'admin/permissions/assigned/'+id
    });
};

const permissionsList = ()=>{
    return fetchRequest({
        url:'admin/permissions',
    });
};

const permissionsToAssign = (obj)=>{
    return fetchRequest({
        url:'admin/permissions/assign',
        method:'POST',
        obj:obj
    });
};

const permissionsToDesign = (obj)=>{
    return fetchRequest({
        url:'admin/permissions/design',
        method:'POST',
        obj:obj
    });
};

const procedures = ()=>{
    return fetchRequest({
        url:'procedures'
    });
};

const proceduresAccount = (obj)=>{
    return fetchRequest({url:'procedures/account', method:'POST', obj:obj});
}

const proceduresId = (id)=>{
    return fetchRequest({
        url:'procedures/'+id
    });
};

const salesConfirmTicket = (id)=>{
    return fetchRequest({url: 'sales/confirm/'+id});
};

const salesDelete = (id)=>{
    return fetchRequest({
        url:'sales/'+id,
        method:'DELETE'
    });
};

const salesDetailsSave = (obj)=>{
    return fetchRequest({url: 'sales/details', method:'POST', obj: obj});
};

const salesStore = (obj)=>{
    return fetchRequest({
        url:'sales',
        method:'POST',
        obj:obj
    });
};

const salesUpdatePassport = (obj)=>{
    return fetchRequest({
        url:'sales/passport',
        method:'PUT',
        obj:obj
    });
};

const salesUpdateWeb = (obj)=>{
    return fetchRequest({
        url:'sales/web',
        method:'PUT',
        obj: obj,
    });
};

const sendVisaForm = (obj)=>{
    return fetchRequest({url:'sales/visa/form', method:'POST', obj:obj});
};

const sitesDelete = (id)=>{
    return fetchRequest({
        url:'admin/sites/'+id,
        method:'DELETE'
    });
};

const sitesId = (id)=>{
    return fetchRequest({
        url:'admin/sites/'+id
    });
};

const sitesList = ()=>{
    return fetchRequest({
        url:'admin/sites'
    });
};

const sitesShow = ()=>{
    return fetchRequest({
        url:'admin/list/sites'
    });
};

const sitesStore = (obj)=>{
    return fetchRequest({
        url:'admin/sites',
        method:'POST',
        obj:obj
    });
};

const sitesUpdate = (id, obj)=>{
    return fetchRequest({
        url:'admin/sites/'+id,
        method:'PUT',
        obj:obj
    });
};

const roles = ()=>{
    return fetchRequest({
        url:'admin/roles'
    });
};

const rolesDelete = (id)=>{
    return fetchRequest({
        url:'admin/roles/'+id,
        method:'DELETE'
    });
};

const rolesEdit = (id)=>{
    return fetchRequest({
        url:'admin/roles/'+id
    });
};

const rolesStore = (obj)=>{
    return fetchRequest({
        url:'admin/roles',
        method:'POST',
        obj:obj
    });
};

const rolesUpdate = (id, obj)=>{
    return fetchRequest({
        url:'admin/roles/'+id,
        method:'PUT',
        obj:obj
    });
};

const rolesVerify = (id)=>{
    return fetchRequest({
        url:'admin/roles/verify/'+id
    });
};

const users = ()=>{
    return fetchRequest({
        url:'admin/users'
    });
};

const usersEdit = (id)=>{
    return fetchRequest({
        url:'admin/users/'+id
    });
};

const usersDelete = (id)=>{
    return fetchRequest({
        url:'admin/users/'+id,
        method:'DELETE'
    });
};

const usersFlyers = ()=>{
    return fetchRequest({ url: 'users/flyers' });
}

const usersReset = (obj)=>{
    return fetchRequest({
        url:'admin/users/reset',
        method:'POST',
        obj:obj
    });
};

const usersSellers = ()=>{
    return fetchRequest({ url: 'users/sellers'});
}

const usersStore = (obj)=>{
    return fetchRequest({
        url:'admin/users',
        method:'POST',
        obj:obj
    });
};

const usersUpdate = (id, obj)=>{
    return fetchRequest({
        url:'admin/users/'+id,
        method:'PUT',
        obj:obj
    });
};

/**
 * Obtiene datos detallado de tramite de todos los usuarios 
 * @params {object} obj
 */
const visasDetails = (obj)=>{
    return fetchRequest({url:'sales/details/visa', method:'POST', obj:obj});
}

/**
 * Obtiene datos detallado de tramite de visa por usuario
 * @param {*} id 
 */
const visasDetailsByUser = (id)=>{
    return fetchRequest({
        url:'sales/details/by_user/'+id
    });
};



export {    
    clients, clientsId, clientsSearch, clientsSendTicket,
    clientsUsersStore,
    login, logout,
    permissionasAssigned, permissionsList, permissionsToAssign, permissionsToDesign, 
    procedures, proceduresAccount, proceduresId,
    salesConfirmTicket, salesDelete, salesDetailsSave, salesStore, salesUpdatePassport, salesUpdateWeb,
    sendVisaForm,
    sitesDelete, sitesId, sitesList, sitesShow, sitesStore, sitesUpdate,
    roles, rolesDelete, rolesEdit, rolesStore, rolesUpdate, rolesVerify,
    users,
    usersDelete, usersEdit, usersFlyers, usersReset, usersSellers, usersStore, usersUpdate,
    visasDetails, visasDetailsByUser
};