import React, { useEffect } from "react";
import { decript } from "../libs/functions";

const Home = ({reset})=>{
    useEffect(()=>{
        let change = sessionStorage.getItem('reset');
        if(change === '0'){
            let id = decript('user_id');
            reset.current.handleShow(id, 'Debe cambiar su contraseña para poder utilizar el sistema'); 
        }
    // eslint-disable-next-line
    },[]);

    return(
        <div className="container-fluid p-3">
            <h1>Home</h1>
        </div>
    )
};

export default Home;