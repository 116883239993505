import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Col, Grid, Drawer } from "rsuite";
import { decript } from "../libs/functions";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import DocPassIcon from '@rsuite/icons/DocPass';

import SystemContext from "../context/SystemContext";

const Layout = ({loader, reset})=>{
    const user = decript('user_name');
    const location = useLocation();
    const navigate = useNavigate();
    const {getPermission} = useContext(SystemContext);

    const [expanded, setExpanded] = useState(false);
    const [showSide, setShow] = useState(true);
    
    const [menu, setMenu] = useState([
        {title:'Dashboard', submenu:[], show:true, active:true, url:'/', icon:<DashboardIcon />},        
        {title:'Trámites', submenu:[], show:getPermission('procedures'), active:true, url:'/procedures', icon:<DocPassIcon />},
        {title:'Admin', show:getPermission('admin_menu'), open:false, icon:<GearCircleIcon />, submenu:[
            {title:'Empleados', show:getPermission('admin_users'), active:false, url:'/admin/employees'},
            {title:'Permisos', show:getPermission('admin_permissions'), active:false, url:'/admin/permissions'},
            {title:'Roles', show:getPermission('admin_roles'), active:false, url:'/admin/roles'},
            {title:'Sitios', show:getPermission('admin_sites'), active:false, url:'/admin/sites'}            
        ]}
    ]);
    
    const onChangeMenu = (open, i)=>{
        let menus = menu.map((m, index)=>{
            if(index === i){
                m.open = open
            }else{
                m.open = false;
            }

            return m;
        });

        setMenu(menus);

        updateMenu();
        
    }

    const onSelectMenu = (url)=>{
        navigate(url);
    }

    const resizeWindow = ()=>{        
        let width = window.innerWidth;
        
        if(width <= 800){
            setShow(false);
        }else{
            setShow(true);
        }
    }

    window.addEventListener('resize', ()=>{
        resizeWindow();   
    });

    const updateMenu = ()=>{
        let menus = menu.map((m)=>{
            if(m.submenu.length === 0){
                if(m.url === location.pathname){
                    m.active = true;
                }else{
                    m.active = false;
                }
            }else{
                m.submenu.forEach((s)=>{
                    if(s.url === location.pathname){
                        
                        s.active = true;
                    }else{
                        s.active = false;
                    }
                });
            }
            

            return m;
        });

        setMenu(menus);
    }

    const onExpand = ()=>{
        setExpanded(!expanded)
    }

    useEffect(()=>{
        
        resizeWindow();    

        updateMenu();

        // eslint-disable-next-line
    },[])


    return(
        <Grid fluid className="p-0 m-0">            
            {showSide ?
                <Col xsHidden smHidden mdHidden lg={expanded ? 4 : 1} className="border p-0 m-0">
                    <Sidebar user={user} expanded={expanded} menu={menu} onChangeMenu={onChangeMenu} onSelectMenu={onSelectMenu} />
                </Col>   
            :
                <Drawer placement="left" open={expanded} onClose={()=>setExpanded(false)} size="xs">                    
                    <Sidebar user={user} expanded={true} menu={menu} onChangeMenu={onChangeMenu} onSelectMenu={onSelectMenu} />
                </Drawer>
            }

            <Col xs={24} lg={expanded ? 20 : 23} className="full-height p-0 m-0" style={{border:'0px solid red'}}>
                <Grid fluid className="border p-0 m-0">
                    <Navbar user={user} expanded={expanded} setExpanded={onExpand} loader={loader} reset={reset} />
                </Grid>
                
                <Outlet />
            </Col> 
        </Grid>
    )
}

export default Layout;