import React, { useContext, useEffect, useRef, useState } from "react";
import { pencil } from "react-icons-kit/fa/pencil";
import { trash } from "react-icons-kit/fa/trash";
import { useNavigate } from "react-router-dom";
import { Button, Col, Divider, Grid } from "rsuite";


import Table from "../../components/Table";
import Title from "../../components/Title";
import Toast from "../../components/Toast";
import SystemContext from "../../context/SystemContext";
import { swalAction } from "../../libs/functions";
import { sitesDelete, sitesList } from "../../libs/services";

const SitesList = (props)=>{
    const {loader} = props;
    const navigate = useNavigate();
    const list = useRef(null);
    const {getPermission} = useContext(SystemContext);

    const columns = [
        {title:'Nombre', width:'120px', show:true},
        {title:'Dirección', width:'120px', show:true}
    ];
    const [data, setData] = useState([]);

    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');

        let response = await sitesList();
        if(response){
            let rows = [];
            response.forEach((res)=>{
                let obj = {
                    id: res.id,
                    row:[
                        res.name,
                        res.ubication,
                    ],
                    buttons:[
                        {icon:pencil, title:'Editar', show: getPermission('sites_update'), action:(id)=>onEdit(id)},
                        {icon:trash, title:'Eliminar', show: getPermission('sites_delete'), action:(id)=>onDelete(id)},
                    ]
                };

                let count = 0;
                obj.buttons.forEach((btn)=>{
                    if(!btn.show){
                        count++;
                    }
                });
                
                if(count === obj.buttons.length){
                    obj.buttons = [];
                }

                rows.push(obj);                
            })

            await list.current.resetTable();
            
            await setData(rows);

            setTimeout(async ()=>{
                await list.current.setTable();
            }, 0)
        }
        

        await loader.current.handleClose();
    }

    const onEdit = (id)=>{
        navigate('/admin/sites/edit/'+id);
    }

    const deleteItem = async(id)=>{
        await loader.current.handleShow('Eliminando...');
        let response = await sitesDelete(id);
        if(response){
            if(response.error){
                Toast.fire({icon:'error', title:'Error', text: response.error, timer:6000});
            }else{
                await getData();
                Toast.fire({icon:'success', title:'Correcto', text:'Se elimino el usuario correctamente'});
            }
            
        }
        await loader.current.handleClose();
    }

    const onDelete  = (id)=>{
        swalAction({
            title           : 'Alerta',
            text            : 'Desea eliminar el registro?',
            icon            : 'warning',
            textConfirm     : 'Si, eliminar',
            textcancel      : 'No, cancelar',
            values          : id,
            fn              : deleteItem
        });
    }

    useEffect(()=>{
        getData();
        // eslint-disable-next-line
    }, [])

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Sitios" action="Listado" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                {getPermission('sites_create') &&
                    <Col xs={24} style={{position:'relative'}}>
                        <Button 
                            appearance='primary' 
                            size='sm'
                            style={{position:'absolute', right:0, marginTop:25, marginRight:25, zIndex:1}}
                            onClick={()=>navigate('/admin/sites/new')}
                        >
                            Nuevo
                        </Button>
                    </Col>
                }
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <Table 
                            columns={columns}
                            dataList={data}
                            ref={list}
                        />                        
                    </div>                    
                </Col>
            </Grid>   
        </Grid>
    )
}

export default SitesList;