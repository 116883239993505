import moment from "moment";
import Swal from "sweetalert2";

const cryptoJs = require("crypto-js")

/**
 * Add error highlighting to select component
 * @param {*} id field identifier 
 * @param {*} value field value
 */
export const addErrorToSelectedField = (id, value)=>{
	let field = document.getElementById(id);
	
	if(field !== null){
		if(value.length === 0){
			field.classList.add('error');
			return;
		}

		field.classList.remove('error');
		
	}
}

const encript = (name, value)=>{
    let found = findInStorage(name);

    let encriptedValue = cryptoJs.AES.encrypt(value, 'secret@@').toString();
    let encriptedName = cryptoJs.AES.encrypt(name, 'secret@@').toString();

    if(found !== ''){
        encriptedName = found;
    }

    sessionStorage.setItem(encriptedName, encriptedValue);
}

const findInStorage = (name)=>{
    let value = '';
	let sessions = Object.keys(sessionStorage);
	if(sessions.length > 0){
		sessions.forEach((key)=>{
			let bytes = cryptoJs.AES.decrypt(key, 'secret@@');
			let decriptedName = bytes.toString(cryptoJs.enc.Utf8);

			if(decriptedName === name){
				value = key;
			}        
		});
	}
    return value;
}

export const viewStorage = ()=>{
	let sessions = Object.keys(sessionStorage);
	if(sessions.length > 0){
		sessions.forEach((key)=>{
			let bytes = cryptoJs.AES.decrypt(key, 'secret@@');
			let decriptedName = bytes.toString(cryptoJs.enc.Utf8);

			console.log(decriptedName, key)       
		});
	}
}

const decript = (name)=>{
    let value = '';
	let sessions = Object.keys(sessionStorage);
	if(sessions.length > 0){
		sessions.forEach((key)=>{
			if(key === 'reset'){
				return sessionStorage.getItem(key);
			}else{
				let bytes = cryptoJs.AES.decrypt(key, 'secret@@');
				let decriptedName = bytes.toString(cryptoJs.enc.Utf8);

				if(decriptedName === name){
					let encriptedValue = sessionStorage.getItem(key);            
					let bytesValue = cryptoJs.AES.decrypt(encriptedValue, 'secret@@');
					value = bytesValue.toString(cryptoJs.enc.Utf8);            
				} 
			}
			       
		});
	}
    return value;
};

const isAuth = ()=>{
    let auth = false;
    Object.keys(sessionStorage).forEach((key)=>{
        let bytes = cryptoJs.AES.decrypt(key, 'secret@@');
        let decriptedName = bytes.toString(cryptoJs.enc.Utf8);

        if(decriptedName === 'token'){
            auth = true;
        }
    });
    
    return auth;
}

const isAlpha = (value)=>{
	return new RegExp(/[a-zA-Z áéíóú]/).test(value);
}

const isNumber = (value)=>{
return new RegExp(/[0-9]/).test(value);
}

//Validar que coincida el dígito verificador
const digitoVerificador = (curp17)=>{
	//Fuente https://consultas.curp.gob.mx/CurpSP/
	var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
		lngSuma      = 0.0,
		lngDigito    = 0.0;

	for(var i=0; i<17; i++)
		lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);

	lngDigito = 10 - lngSuma % 10;

	if (lngDigito === 10) return '0';

	return lngDigito.toString();
}

const isValidCurp = (curp)=>{
    let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    let validado = curp.match(re);
	
    if (!validado)  //Coincide con el formato general?
    	return false;
  
    if (validado[2] !== digitoVerificador(validado[1])) 
    	return false;
        
    return true; //Validado
}

const isValidForm = (element)=>{
	var ctrls = [];
	const select = document.querySelector(element);

	if(select !== null){
		ctrls = select.querySelectorAll('input, select');
   	
	    let isFormValid = true;
    	 ctrls.forEach(ctrl => {
	    	if(ctrl.required){
		      	const isInputValid = showCtrlError(ctrl.id);
		      	if (!isInputValid) {
		        	isFormValid = false;
		    	}
		  	}
	    });
	   
	    return isFormValid;
	}

	return true;

};

/**
 * verify if field is email
 * @param {string} value 
 * @returns boolean
 */
export const isEmail = (value)=>{
    // Regular expression to validate an email address
    var patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //Check if the email matches the pattern
    return patron.test(value);
}


const showCtrlError = (id)=>{
	var res = null;
	var control = document.getElementById(id);

	if(control !== null){
		if(control.value !== undefined){
			if (control.value.trim() === "") {
				if(control !== null){
					control.classList.add('error');
				}
				res = false;
			} else{
				if(control !== null){
					if(control.required && control.className.includes('error')){
						control.classList.remove('error');
					}
				}
				res = true;
			}
		}
		
	}

	return res;
};

const swalAction = (obj)=>{
	Swal.fire({
		title 				: obj.title,
		text 				: obj.text,
		icon 				: obj.icon,
		showConfirmButton	: true,
		showCancelButton	: true,
		confirmButtonText	: obj.textConfirm,
		cancelButtonColor	: obj.colorCancel,
		cancelButtonText	: obj.textcancel
	}).then(result => {
		if(result.isConfirmed){
			obj.fn(obj.values);
		}else{
			if(obj.fnCancel !== undefined){
				obj.fnCancel(obj.values);
			}
		}
	});
}

const transformAmount = (amount)=>{
	let separator = ',';
	let sepDecimal = '.';

	let num = amount;
	num += '';

	let splitStr = num.split('.');
	let splitLeft = splitStr[0];
	let splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
	let regx = /(\d+)(\d{3})/;

	while (regx.test(splitLeft)) {
		splitLeft = splitLeft.replace(regx, '$1' + separator + '$2');
	}

	return splitLeft  +splitRight;
}

const hasPermission = (name)=>{
	let exist = false;

	let permissions = decript('permissions');
	if(permissions !== ''){
		permissions = JSON.parse(permissions);

		let index = permissions.findIndex(obj => obj === name);

		if(index !== -1){
			exist = true;
		}
	}

	return exist;
}

/**
 * Calcular edad de paciente
 * @param {string} date fecha de nacimiento format YYYY-MM-DD
 */
export const calculateAge = (date)=>{
    let birth = moment(date, 'DD/MM/YYYY');
    let today = moment();

    let age = today.diff(birth, "years");

    return age;
}


export {
	encript, decript, findInStorage, hasPermission, isAuth, isAlpha, isNumber, 
	isValidCurp, isValidForm, showCtrlError, swalAction, transformAmount
};