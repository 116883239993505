import React from "react";
import {Col, Button} from 'rsuite';

const DataForm = ()=>{
    return(
        <>
             <fieldset>
                <legend>Pasaporte</legend>
                <Col xs={24}>
                    <Col xs={24} md={8} xl={6}>
                        <label>No. Pasaporte</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>País expedición</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>Ciudad expedición</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>Estado de expedición</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>País emisor</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                        <label>F. expedición</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                        <label>F. caducidad</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                </Col>
            </fieldset>
            <fieldset>
                <legend>Cliente</legend>
                <Col xs={24}>
                    <Col xs={24} md={8} xl={6}>
                        <label>Nombre(s)</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>Ap. Paterno</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>Ap. Materno</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                        <label>F. Nacimiento</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                        <label>Sexo</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>No. Identificación personal</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <label>Edo. Civil</label>
                        <input className="form-control form-control-sm" />
                    </Col>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Lugar de nacimiento</legend>
                        <Col xs={24}>
                            <Col xs={24} sm={12} xl={6}>
                                <label>País</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Estado</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Ciudad</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Nacionalidad</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Teléfonos</legend>
                        <Col xs={24}>
                            <Col xs={24} sm={12} md={8} xl={6}>
                                <label>Domicilio</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} md={8} xl={6}>
                                <label>Trabajo</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} md={8} xl={6}>
                                <label>Otro</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Dirección</legend>
                        <Col xs={24}>
                            <Col xs={24} md={12} xl={6}>
                                <label>Calle</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={6} xl={6}>
                                <label>No. Int</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={6} xl={6}>
                                <label>No. Ext.</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} md={6} xl={6}>
                                <label>Código postal</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Colonia</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} md={6} xl={6}>
                                <label>País</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Estado</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Ciudad</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Generales</legend>
                        <Col xs={24}>
                            <Col  xs={24} sm={12} xl={6}>
                                <label>Nombre completo de esposa/esposo</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} xl={6}>
                                <label>Fecha de nacimiento esposa/esposo</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                        <Col xs={24}>
                            <Col  xs={24} sm={12} xl={6}>
                                <label>Nombre Papá</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} md={6} xl={6}>
                                <label>F. nacimiento</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} md={6} xl={6}>
                                <label>¿Tiene visa?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                        <Col xs={24}>
                            <Col  xs={24} sm={12} xl={6}>
                                <label>Nombre Mamá</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} md={6} xl={6}>
                                <label>Fecha de nacimiento</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} md={6} xl={6}>
                                <label>¿Tiene visa?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                        <Col xs={24} className="flex align-items-end">
                            <Col xs={24}>
                                <label>Personas con las que viaja</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                        
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Ocupación</legend>
                        <Col xs={24}>
                            <Col  xs={24} md={12} xl={8}>
                                <label>Nombre de empleo o escuela</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} md={12} xl={12}>
                                <label>Dirección de empleo o escuela</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} mdm={12} xl={8}>
                                <label>Ocupación actual (indicar si es jubilado)</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col  xs={24} sm={12} xl={6}>
                                <label>Sueldo mensual</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <label>Antiguedad en su trabajo</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            
                            
                        </Col>
                        
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Estudios</legend>
                        <Col xs={24} >
                            <Col xs={24} md={12} lg={8}>
                                <label>Escuela</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <label>Dirección</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={4}>
                                <label>Periodo</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={4} className="mt-2">
                                <Button style={{width:'100%', marginTop:16}} appearance="ghost" size="sm">Agregar</Button>
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Estancia/Hospedaje</legend>
                        <Col xs={24}>
                            <Col xs={24} md={16} xl={18}>
                                <span>Nombre completo</span>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <span>Teléfono Personal</span>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <span>Teléfono Trabajo (Quien lo hospeda)</span>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <label>No. Celular (Persona que lo hospeda)</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
                <Col xs={24}>
                    <fieldset>
                        <legend>Visa</legend>
                        <Col xs={24}>
                            <Col xs={24} md={8} xl={6}>
                                <label>Visa anterior</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={10} xl={6}>
                                <label>¿Has intentado sacar visa antes?</label>
                                <select className="form-control form-control-sm"></select>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                                <label>¿Cuando?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                        <Col xs={24}>
                            <Col xs={24} md={12} xl={6}>
                                <label>¿Cuál será su dirección en los EEUU?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <label>¿Cuándo planea viajar a los EEUU?</label>
                                <input className="form-control form-control-sm" />
                            </Col>                                                    
                            
                            <Col xs={24} md={12} xl={8}>
                                <label style={{fontSize:13}}>¿Cuánto tiempo planea permanecer en los EEUU?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={8} xl={6}>
                                <label>¿Cuál será el motivo de su viaje?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={8} xl={6}>
                                <label>¿Quién cubrirá los gastos de su viaje?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} xl={8}>
                                <label>¿Ha visitado los EEUU alguna vez?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={8} xl={6}>
                                <label>¿Cuándo?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} lg={8} xl={6}>
                                <label>¿Por cuánto tiempo?</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24}>
                                <label>Observaciones</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} xl={8}>
                                <label>Usuario</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} xl={8}>
                                <label>Contraseña</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} md={12} xl={8}>
                                <label>Clave DS 160</label>
                                <input className="form-control form-control-sm" />
                            </Col>
                        </Col>
                    </fieldset>
                </Col>
            </fieldset>
        </>
    )
}

export default DataForm;