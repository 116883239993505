import React from "react"
import { decript } from "../libs/functions";

import SystemContext from "./SystemContext";

const array_country = require('../libs/jsonCountries/countries.json');
const array_state = require('../libs/jsonCountries/states.json');
const array_cities = require('../libs/jsonCountries/cities.json');

const SystemState = ({children})=>{
    
    const getCountryName = (name)=>{
        let find = [];
        array_country.countries.forEach(obj => {
            if(obj.name.toUpperCase().indexOf(name.toUpperCase()) !== -1){
                find.push(obj.name);
            }
        });

        return find;
    }

    const getCountryId = (name)=>{
        let id = '';
        array_country.countries.forEach(obj => {
            if(obj.name === name){
                id = obj.id
            }
        });

        return id;
    }

    const getStateName = (country, state)=>{
        let id = getCountryId(country);
        let find = [];
        array_state.states.forEach(obj => {
            if(obj.name.toUpperCase().indexOf(state.toUpperCase()) !== -1 && id === obj.id_country){
                find.push(obj.name);
            }
        });

        return find;
    }

    const getStateId = (name)=>{
        let id = '';
        array_state.states.forEach(obj => {
            if(obj.name === name){
                id = obj.id
            }
        });

        return id;
    }

    const getCitiesName = (state, city)=>{
        let id = getStateId(state);
        let find = [];
        array_cities.cities.forEach(obj => {
            if(obj.name.toUpperCase().indexOf(city.toUpperCase()) !== -1 && id === obj.id_state){
                find.push(obj.name);
            }
        });

        return find;
    }

    const getPermission = (name)=>{
        let permissions = JSON.parse(decript('permissions'));
    
        let exist = permissions.findIndex(item => item === name);

        return exist !== -1        
    }

    return(
        <SystemContext.Provider value={{
            getPermission,
            getCountryName,
            getStateName,
            getCitiesName
        }}>
            {children}            
        </SystemContext.Provider>
    )

}

export default SystemState;