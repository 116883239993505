import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Grid } from 'rsuite';
import Table from '../../components/Table';
import Title from '../../components/Title';

import {pencil} from 'react-icons-kit/fa/pencil';
import {trash} from 'react-icons-kit/fa/trash';
import {key} from 'react-icons-kit/icomoon/key';

import { swalAction } from '../../libs/functions';
import {users, usersDelete} from '../../libs/services';
import Toast from '../../components/Toast';
import SystemContext from '../../context/SystemContext';

const Users = ({loader, reset})=>{
    const list = useRef();
    const {getPermission} = useContext(SystemContext)
    
    const navigate = useNavigate();

    const columns = [
        {title:'Nombre', width:'120px', show:true},
        {title:'Email', width:'120px', show:true},
        {title:'Meta', width:'50px', show:true},
        {title:'Comisión', width:'50px', show:true},
        {title:'Salario', width:'60px', show:true},
        {title:'Rol', width:'120px', show:getPermission('admin_users_role_assign')}
    ];

    const [data, setData] = useState([]);

    const onEdit = (id)=>{
        navigate('/admin/employees/edit/'+id);
    }

    const deleteItem = async(id)=>{
        await loader.current.handleShow('Eliminando...');
        let response = await usersDelete(id);
        if(response){
            await getData();
            Toast.fire({icon:'success', title:'Correcto', text:'Se elimino el usuario correctamente'});
        }
        await loader.current.handleClose();
    }

    const onDelete  = (id)=>{
        swalAction({
            title           : 'Alerta',
            text            : 'Desea eliminar el registro?',
            icon            : 'warning',
            textConfirm     : 'Si, eliminar',
            textcancel      : 'No, cancelar',
            values          : id,
            fn              : deleteItem
        });
    }

    const onReset = (id)=>{
        reset.current.handleShow(id);
        
    }

    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');
        let response = await users();
        if(response){
            let rows = [];
            response.forEach((res)=>{
                let obj = {
                    id: res.id,
                    row:[
                        res.names+' '+res.lastname1+' '+(res.lastname2 !== null ? res.lastname2 : ''),
                        res.email,
                        res.goal,
                        res.commission,
                        res.salary,
                        res.role
                    ],
                    buttons:[
                        {icon:key, title:'Restablecer contraseña', show:getPermission('admin_users_change_password'), action:(id)=>onReset(id)},
                        {icon:pencil, title:'Editar', show:getPermission('admin_users_update'), action:(id)=>onEdit(id)},
                        {icon:trash, title:'Eliminar', show:getPermission('admin_users_delete'), action:(id)=>onDelete(id)},
                    ]
                };

                if(!getPermission('admin_users_role_assign')){
                    obj.row.splice(5, 1);
                }

                let count = 0;
                obj.buttons.forEach((btn)=>{
                    if(!btn.show){
                        count++;
                    }
                });
                
                if(count === obj.buttons.length){
                    obj.buttons = [];
                }

                rows.push(obj);                
            })
            
            await list.current.resetTable();
        
            await setData(rows);

            setTimeout(async ()=>{
                await list.current.setTable();
            }, 0)
        }

        await loader.current.handleClose();
    }

    useEffect(()=>{
       getData(); 
       // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Empleados" action="Listado" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                {getPermission('admin_users_create') &&
                    <Col xs={24} style={{position:'relative'}}>
                        <Button 
                            appearance='primary' 
                            size='sm'
                            style={{position:'absolute', right:0, marginTop:25, marginRight:25, zIndex:1}}
                            onClick={()=>navigate('/admin/employees/new')}
                        >
                            Nuevo
                        </Button>
                    </Col>
                }
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <Table 
                            columns={columns}
                            dataList={data}
                            ref={list}
                        />                        
                    </div>                    
                </Col>
            </Grid>   
        </Grid>
    )
}

export default Users;