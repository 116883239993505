import React, { useEffect } from "react";
import {Col, Grid, Divider, Button} from 'rsuite';

import Title from '../../components/Title';
import DataForm from "./DataForm";

import { decript } from "../../libs/functions";
import {visasDetails} from "../../libs/services";
import { useNavigate, useParams } from "react-router-dom";


const Details = ({loader})=>{
    const {id} = useParams();
    const navigate = useNavigate();
 
    const onLoad = async ()=>{
        await loader.current.handleShow('Cargando...');

        let userId = decript('user_id');
        
        let obj = {
            id: id ? id : userId,
            by: id ? 'sale' : 'user'
        };
        
        let response = await visasDetails(obj);
        if(response){
            
        }
        

        await loader.current.handleClose();
    }

    useEffect(()=>{
        onLoad();
        // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Trámites" action="Solicitud de visa de no inmigrante" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <div className='p-2 border row justify-content-center'>
                            <div className='p-2 col-12 col-sm-10 col-md-12 col-lg-10'>
                                <form>
                                   <DataForm />
                                    <Col xs={24}>
                                        <Divider />
                                        <div className='pt-2 col-12 text-center'>
                                            <Button appearance='ghost' className='me-3' onClick={()=>navigate('/procedures')}>Cancelar</Button>
                                            <Button appearance='primary' >Guardar</Button>
                                        </div>
                                    </Col>
                                </form>
                            </div>
                        </div>
                    </div>
                </Col>
                
                
            </Grid>
        </Grid>
    )
}

export default Details;