import React, { useEffect, useRef, useState } from 'react';
import { Col, Divider, Grid } from 'rsuite';
import Table from '../components/Table';
import Title from '../components/Title';
import { permissionsList } from '../libs/services';

const Permissions = ({loader})=>{
    const list = useRef();

    const columns = [
        {title:'Nombre', show:true},
        {title:'Nombre identificador', show:true},
        {title:'Descripción', show:true}
    ];

    const [data, setData] = useState([]);

    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');

        let response = await permissionsList();
        if(response){
            let rows = [];
            response.forEach((res)=>{
                let obj = {
                    id: res.id,
                    row:[
                        res.name,
                        res.display_name,
                        res.description
                    ],
                    buttons:[]
                };

                rows.push(obj);                
            })
            
            await list.current.resetTable();

            await setData(rows);

            setTimeout(async ()=>{
                await list.current.setTable();				
            }, 0)
        }

        await loader.current.handleClose();
    }

    useEffect(()=>{
       getData(); 
       // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Permisos" action="Listado" />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>
                        <Table 
                            columns={columns}
                            dataList={data}
                            ref={list}
                        />
                    </div>
                    
                </Col>
            </Grid>
            
        </Grid>
    )
}

export default Permissions;