import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {Modal, Grid, Col, ButtonGroup, IconButton, Whisper, Tooltip, Row} from 'rsuite';
import moment from "moment";

import {calendar} from 'react-icons-kit/fa/calendar';
import {userPlus} from 'react-icons-kit/fa/userPlus';
import {plusSquareO} from 'react-icons-kit/fa/plusSquareO';
import {wpforms} from 'react-icons-kit/fa/wpforms';
import {Icon} from 'react-icons-kit';


import { proceduresId } from "../../../libs/services";
import ModalSchedule from "../modals/ModalSchedules";
import ModalAccount from "../modals/ModalAccount";
import { transformAmount } from "../../../libs/functions";
import { Table } from "react-bootstrap";

const ModalProcedureInfo = forwardRef(({loader}, ref)=>{
    const schedule = useRef(null);
    const account = useRef(null);

    const [open, setOpen] = useState(false);
    const [sales, setSales] = useState({
        clients:[],
        contact_email:"",
        contact_name: "",
        contact_phone:'',
        date:"",
        folio:"",
        id:'',
        name:"",
        package:"",
        payable:"",
        schedule_details:[],
        status:"",
        total:"",
        cas:'',
        consul:'',
        email:'',
        password:''
    });

    const getData = async (id)=>{
        //await loader.current.handleShow('Cargando...');

        let response = await proceduresId(id);
        if(response){
            response.date = moment(response.date).format('DD/MM/YYYY')
            response.schedule_details.forEach((res)=>{
                if(res.office === 'CAS'){
                    console.log(response.appointment_date)
                    response.cas = moment(res.appointment_date).format('DD/MM/YYYY')+' '+res.schedule;
                }

                if(res.office === 'Consulado'){
                    response.consul = moment(res.appointment_date).format('DD/MM/YYYY')+' '+res.schedule;
                }
            });

            setSales(response);
            setOpen(true);
        }

        //await loader.current.handleClose();
    }

    const handleShow = (id)=>{
        getData(id);
    }

    const handleClose = ()=>{
        setOpen(false);
    }

    const setScheduleProcedure = ()=>{        
        schedule.current.handleShow(sales.id, sales.cas, sales.consulado);
    }

    const setAccountProcedure = ()=>{
        account.current.handleShow(sales.id);
    }

    useImperativeHandle(ref, ()=>({
        handleShow
    }));

    return(
        <>
        <Modal size="lg" open={open} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title>información de trámite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Row>
                        <Col xs={24} lg={16}>
                            <fieldset>
                                <legend>Trámite</legend>
                                <Row className="flex">
                                    <Col xs={24} lg={12}>
                                        <label><b>Oficina</b></label> 
                                        <input className="form-control form-control-sm" value={sales.site} disabled/>
                                    </Col>                                    
                                    <Col xs={24} lg={12} className="flex align-items-end">
                                        <ButtonGroup>
                                            <Whisper placement="bottom" controlId="calendarId" trigger={"hover"} speaker={
                                                <Tooltip>
                                                    Agregar cita CAS y/0 CONSULADO
                                                </Tooltip>
                                            }>
                                                <IconButton 
                                                    className="me-2"
                                                    circle 
                                                    size="sm" 
                                                    icon={<Icon icon={calendar} />} 
                                                    appearance="ghost"
                                                    onClick={()=>setScheduleProcedure()}
                                                />
                                            </Whisper>

                                            <Whisper placement="bottom" controlId="userId" trigger={"hover"} speaker={
                                                <Tooltip>
                                                    Usuario y contraseña
                                                </Tooltip>
                                            }>
                                                <IconButton 
                                                    className="me-2"
                                                    circle 
                                                    size="sm" 
                                                    icon={<Icon icon={userPlus} />} 
                                                    appearance="ghost"
                                                    onClick={()=>setAccountProcedure()}
                                                />
                                            </Whisper>

                                            <Whisper placement="bottom" controlId="moreId" trigger={"hover"} speaker={
                                                <Tooltip>
                                                    Servicios adicionales
                                                </Tooltip>
                                            }>
                                                <IconButton 
                                                    className="me-2"
                                                    circle 
                                                    size="sm" 
                                                    icon={<Icon icon={plusSquareO} />} 
                                                    appearance="ghost"
                                                    onClick={()=>setAccountProcedure()}
                                                />
                                            </Whisper>

                                            <Whisper placement="bottom" controlId="detailsId" trigger={"hover"} speaker={
                                                <Tooltip>
                                                    Más detalles
                                                </Tooltip>
                                            }>
                                                <IconButton 
                                                    className="me-2"
                                                    circle 
                                                    size="sm" 
                                                    icon={<Icon icon={wpforms} />} 
                                                    appearance="ghost"
                                                    onClick={()=>setAccountProcedure()}
                                                />
                                            </Whisper>
                                            
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={6}>
                                        <label><b>Folio</b></label> 
                                        <input className="form-control form-control-sm" value={sales.folio} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Fecha</b></label> 
                                        <input className="form-control form-control-sm" value={sales.date} disabled/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} lg={6}>
                                        <label><b>Trámites</b></label> 
                                        <input className="form-control form-control-sm" value={sales.package} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Total</b></label> 
                                        <input className="form-control form-control-sm" value={`$ ${transformAmount(sales.total)}`} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Total</b></label> 
                                        <input className="form-control form-control-sm" value={`$ ${transformAmount(sales.payable)}`} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Estatus</b></label> 
                                        <input className="form-control form-control-sm" value={sales.status} disabled/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={24} lg={6}>
                                        <label><b>CAS</b> </label> 
                                        <input className="form-control form-control-sm" value={sales.cas} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Consulado</b></label> 
                                        <input className="form-control form-control-sm" value={sales.consul} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Usuario</b> </label> 
                                        <input className="form-control form-control-sm" value={sales.email} disabled/>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <label><b>Contraseña</b></label> 
                                        <input className="form-control form-control-sm" value={sales.password} disabled/>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                        <Col xs={24} lg={8}>
                            <fieldset>
                                <legend>Contacto</legend>
                                <Row>
                                    <Col xs={24}>
                                        <label><b>Nombre</b></label> 
                                        <input className="form-control form-control-sm" value={sales.contact_name} disabled/>
                                    </Col>
                                    <Col xs={24} >
                                        <label><b>Correo</b></label> 
                                        <input className="form-control form-control-sm" value={sales.contact_email} disabled/>
                                    </Col>
                                    <Col xs={24} >
                                        <label><b>Teléfono</b></label> 
                                        <input className="form-control form-control-sm" value={sales.contact_phone} disabled/>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <fieldset>
                                <legend>Solicitantes</legend>
                                <Row>
                                    <Col xs={24}>
                                        <Table responsive hover>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Trámite</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sales.clients.map((client, i)=>
                                                    <tr key={i}>
                                                        <td>{client.names+' '+client.lastname1+' '+client.lastname2}</td>
                                                        <td>
                                                            {(client.type === 'visa' ? 'Visa' : 'Pasaporte')
                                                            +' - '+client.age_type+' - '+
                                                            (client.visa_type ? client.visa_type+' - ' : '')+
                                                            client.time_type+
                                                            (client.option_type !== '' ? ' - '+client.option_type : '')}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
        </Modal>

        <ModalSchedule getData={getData} loader={loader} ref={schedule} />
        <ModalAccount getData={getData} loader={loader} ref={account} />
        </>
    )
})

export default ModalProcedureInfo;