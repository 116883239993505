import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Divider, Grid } from "rsuite";

import Title from "../../components/Title";
import Toast from "../../components/Toast";
import { isValidForm, showCtrlError } from "../../libs/functions";
import {sitesId, sitesStore, sitesUpdate} from '../../libs/services';

const SitesForm = ({loader})=>{
    const navigate = useNavigate();
    const {id} = useParams();

    const [site, setSite] = useState({
        name:'',
        ubication:''
    });

    const handleChange = (e)=>{
        let name = e.target.name;
        let value = e.target.value;

        setSite({
            ...site,
            [name]:value,
        });

        if(value !== ''){
            showCtrlError(name);
        }
    }

    const getData = async ()=>{
        await loader.current.handleShow('Cargando...');
        let response = await sitesId(id);
        if(response){
            setSite(response);
        }

        await loader.current.handleClose();
    }
    
    const onSubmit = async ()=>{
        if(isValidForm('div.sites-form')){
            await loader.current.handleShow();
            let response;

            if(id){
                response = await sitesUpdate(id, site);
            }else{
                response = await sitesStore(site);                
            }

            if(response){
                Toast.fire({icon:'success', title:'Correcto', text:response.mensaje});
                navigate('/admin/sites');
            }

            await loader.current.handleClose();
        }else{
            Toast.fire({title:'Error', text:'Campos incompletos', icon:'error'});
        }
        
        
    }

    useEffect(()=>{
        if(id){
            getData();
        }
        // eslint-disable-next-line
    },[id]);

    return(
        <Grid fluid className='content'>
            <Grid fluid>
                <Col xs={24} className="mb-2">
                    <Title screen="Sitios" action={id ? "Edición" : "Registro"} />
                </Col>
            </Grid>            
            <Divider style={{marginTop:0}} />
            <Grid fluid>
                <Col xs={24}>
                    <div className='p-4 shadow rounded form-content'>    
                        <Grid fuild>
                            <Col xs={24} xl={20} xlOffset={2} className="sites-form">
                                <Col xs={24}>
                                    <Col xs={24} xl={8}>
                                        <string>Nombre</string>
                                        <input 
                                            className="form-control form-control-sm"
                                            name="name"
                                            id="name"
                                            value={site.name}
                                            onChange={(e)=>handleChange(e)}
                                            required
                                        />
                                    </Col>
                                </Col>
                                <Col xs={24}>
                                    <Col xs={24}>
                                        <string>Ubicación</string>
                                        <input 
                                            className="form-control form-control-sm"
                                            name="ubication"
                                            id="ubication"
                                            value={site.ubication}
                                            onChange={(e)=>handleChange(e)}
                                            required
                                        />
                                    </Col>
                                </Col>
                                <Col xs={24}>
                                    <Divider />
                                    <div className='pt-2 col-12 text-center'>
                                        <Button appearance='ghost' className='me-3' onClick={()=>navigate('/admin/employees')}>Cancelar</Button>
                                        <Button appearance='primary' onClick={()=>onSubmit()}>Guardar</Button>
                                    </div>
                                </Col>
                            </Col>
                        </Grid>            
                    </div>                    
                </Col>
            </Grid>   
        </Grid>
    )
}

export default SitesForm;