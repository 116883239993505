import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, ButtonToolbar, IconButton, Whisper, Popover, Dropdown } from 'rsuite';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import $ from 'jquery';
import Icon from 'react-icons-kit';

$.DataTable = require('datatables.net-bs5');
$.DataTable = require('datatables.net-responsive-dt' );

const MenuPopover = forwardRef(({ onSelect, options, ...rest }, ref) => (
    <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
            {options.map((option, i)=>
                <Dropdown.Item key={i} eventKey={option.key}>{option.label}</Dropdown.Item>
            )}
        </Dropdown.Menu>
    </Popover>
));
  

const Table = forwardRef(({
    classes='table display table-responsive table-hover responsive',
    columns,
    dataList,
    search=true
}, ref)=>{
    const table = useRef();
    const menuSendForm = useRef(null)

    const setTable = async ()=>{
        let element = $('#table');

        element.DataTable({
            dom:"<'row'<'col-sm-12 col-md-6 filter'f><'col-sm-12 col-md-6'B>>" +
					"<'row'<'col-sm-12'tr>>" +
					"<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
			//buttons:buttons,			
			oLanguage:{
				"sProcessing":"Procesando...",
				"sZeroRecords":"No se encontraron resultados",
				"sEmptyTable":"Ningún dato disponible en esta tabla",
				"sInfo":"Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
				"sInfoEmpty":"Mostrando registros del 0 al 0 de un total de 0 registros",
				"sInfoFiltered":"(filtrado de un total de _MAX_ registros)",
				"sInfoPostFix":"",
				"sSearch":"",
				"sSearchPlaceholder":"Filtrar",
				"sUrl":"",
				"sInfoThousands":",",
				"sLoadingRecords":"Cargando...",
				"oPaginate":{
					"sFirst":"Primero",
					"sLast":"Último",
					"sNext":"Siguiente",
					"sPrevious":"Anterior"
				},
				"fnInfoCallback":null,
			},
			paging:true,
			responsive:true,
			searching: search,
			//scrollY:"20em",
			//scrollX: true,
			autoWidth: true,
			info:false,
			ordering:false,
            columnDefs: [
                { responsivePriority: 0, targets: -1, width:'120px' }
            ]
        })
    }

    const resetTable = ()=>{
        let element = $('#table').DataTable();

        element.destroy();
    }

    useImperativeHandle(ref, ()=>({
        setTable,
        resetTable
    }));

    return(
        <>
            <Row>
                <Col xs={24}>
                    <table width="100%" style={{width:'100%'}} id="table" className={classes} ref={table}>
                        <thead>
                            <tr>
                                {columns.map((col, i)=>
                                    col.show &&
                                        <th key={i}>{col.title}</th>
                                )}
                                {dataList.length > 0 ?
                                    dataList.findIndex(obj => obj.buttons.length > 0) !== -1 &&
                                   //dataList[0].buttons.length > 0 &&
                                        <th style={{width:150}}>Acciones</th>
                                :null}
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((data, i)=>
                                <tr key={i}>
                                    {data.row.map((d, j)=>
                                        <td style={{fontSize:11}} key={j}>{d}</td>
                                    )}

                                    {dataList.length > 0 ?
                                        dataList.findIndex(obj => obj.buttons.length > 0) !== -1 &&                                        
                                           <td>
                                                <ButtonToolbar>
                                                    {data.buttons.map((b, j)=>
                                                        b.show && (
                                                            b.name === 'send_form' || b.name === 'send_ticket' || b.name === 'resend_ticket' ?
                                                                <Whisper 
                                                                    key={j}
                                                                    placement="left" 
                                                                    trigger="click" 
                                                                    controlId="control-id-click" 
                                                                    ref={menuSendForm}
                                                                    speaker={
                                                                        <MenuPopover 
                                                                            options={b.options}
                                                                            onSelect={(e)=>{
                                                                                b.action(e);
                                                                                menuSendForm.current.close();
                                                                            }} 
                                                                        />
                                                                    }
                                                                >
                                                                    <IconButton size="xs"  icon={<Icon icon={b.icon} />} title={b.title} appearance="primary" circle  />
                                                                </Whisper>
                                                            :
                                                                <IconButton size="xs" key={j} icon={<Icon icon={b.icon} />} title={b.title} appearance="primary" circle onClick={()=>b.action(data.id, data.row[0])} />
                                                        )
                                                    )}
                                                </ButtonToolbar>
                                            </td>
                                    :null}
                                    
                                </tr>
                            )}
                        </tbody>
                    </table>    
                </Col>
            </Row>
        </>
    )
});

export default Table;