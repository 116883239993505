import React, {forwardRef, useState, useImperativeHandle} from "react";
import { Button, Col, Grid, Modal } from "rsuite";
import Toast from "../../../components/Toast";
import isEmail from 'validator/lib/isEmail';
import { isValidForm } from "../../../libs/functions";
import { proceduresAccount } from "../../../libs/services";

const ModalAccount = forwardRef(({getData, loader}, ref)=>{
    const [open, setOpen] = useState(false);
    const [account, setAccount] = useState({
        email:'',
        password:''
    });
    const [errorEmail, setErrorEmail] = useState('');
    const [salesId, setSalesId] = useState('');

    const handleShow = (sales_id)=>{
        setOpen(true);
        setSalesId(sales_id);
    }

    const handleClose = ()=>{
        setOpen(false);
        setSalesId('');
        setAccount({
            email:'',
            password:''
        });
    }

    const handleChange = (e)=>{
        let name = e.target.name;
        let value = e.target.value;

        setAccount({
            ...account,
            [name]: value
        });

        if(name === 'email'){
            if(value === ''){
                setErrorEmail('');
            }else{
                if(!isEmail(value)){ 
                    setErrorEmail('Correo electrónico no valido');
                }else{
                    setErrorEmail('');
                }
            }
        }
    }

    const onAssignAccount = async ()=>{
        await loader.current.handleShow();

        let error = '';
        
        if(!isValidForm('div.account-form')){
            error = 'Campos incompletos'
        }

        if(errorEmail !== ''){
            error = 'Correo electrónico no valido';
        }

        let obj = {
            sales_id: salesId,
            email: account.email,
            password: account.password
        };
        
        if(error === ''){
            let response = await proceduresAccount(obj);
            if(response){
                Toast.fire('Correcto', response.message, 'success');
                getData(salesId);
                handleClose();
            }
        }else{
            Toast.fire('Error', error, 'error');
        }
        
        await loader.current.handleClose();
        
    }

    useImperativeHandle(ref, ()=>({
        handleShow
    }));

    return(
        <Modal open={open} size="xs">
            <Modal.Header>
                <Modal.Title>Asignar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Col xs={24} className="account-form">
                        <div className="mb-2">                            
                            <Col xs={24}>
                                <label>Correo</label> {errorEmail !== '' && <span style={{fontSize:11, color:'red', marginLeft:8}}>{errorEmail}</span>}
                                <input
                                    className="form-control form-control-sm"
                                    value={account.email}
                                    name="email"
                                    id="email"
                                    required
                                    onChange={(e)=>handleChange(e)}
                                />
                            </Col>
                            <Col xs={24}>
                                <label>Contraseña</label>
                                <input
                                    className="form-control form-control-sm"
                                    value={account.password}
                                    name="password"
                                    id="password"
                                    required
                                    type="password"
                                    onChange={(e)=>handleChange(e)}
                                />
                            </Col>
                        </div>                        
                    </Col>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="ghost" className="btn-cancel" onClick={()=>handleClose()}>Cancelar</Button>
                <Button appearance="primary" className="btn-payment" onClick={()=>onAssignAccount()}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
});

export default ModalAccount;