import React, { useState } from "react";
import { Col, Divider, Grid, Nav, Sidenav } from "rsuite";

const logo = require('../assets/images/logo.png');

const Sidebar = ({expanded, menu, onChangeMenu, onSelectMenu})=>{
	const [activeKey, setActiveKey] = useState('1');
	
    return(
		<Grid fluid className="p-0 m-0 full-height">
			<Grid fluid className="pt-2 text-center">
				<Col xs={12} md={6} lg={expanded ? 12 : 24} lgOffset={expanded ? 6 : 0} className="p-0 pb-2">
					<img src={logo} className="img-fluid" alt="logo" />
				</Col>
				<Col xs={24} lgHidden={!expanded} xlHidden={!expanded} xxlHidden={!expanded} lg={24}>
					<h3 style={{fontSize:'99%'}} className="login-title">Servicios Premier</h3>					
				</Col>
			</Grid>			
			<Divider />
			<Sidenav expanded={expanded} appearance="subtle">
				<Sidenav.Body>
					<Nav activeKey={activeKey} onSelect={setActiveKey}>
						{menu.map((m, i)=>
							m.show ? 
								m.submenu.length === 0 ?
									<Nav.Item key={i} eventKey={i+1} onClick={()=>onSelectMenu(m.url)} icon={m.icon}>
										{m.title}
									</Nav.Item>
								: 
									<Nav.Menu key={i} open={m.open} onToggle={(open)=>onChangeMenu(open, i)} placement="rightStart" eventKey={i+1} title={m.title} icon={m.icon}>
										{m.submenu.map((submenu, j)=>
											submenu.show &&
												<Nav.Item key={j} eventKey={(i+1)+(j+1)} active={submenu.active} onClick={()=>onSelectMenu(submenu.url)}>
													{submenu.title}
												</Nav.Item>
										)}
									</Nav.Menu>
							: null
						)}
					</Nav>
				</Sidenav.Body>
			</Sidenav>
		</Grid>
    )
}

export default Sidebar;